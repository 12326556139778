import http from "../util/http";
import { useMutation,useQuery } from "react-query";
import { apiConfigration ,getPagination} from "../config/api";

async function getAdminActivityLog({queryKey}){
    const {limit,pageIndex,search,id} = queryKey[1]
    const {pageSize} = getPagination() 

    const { data } =await http.get(`${apiConfigration.user.activity}/${id}?search=${search}&pageIndex=${(pageIndex)}&limit=${limit}&skip=${(pageIndex) * pageSize}`)

    return data;
}


export function useFetchAdminActivityLogQuery(options){
    return useQuery(
        ['AdminActivityLog', options],
        getAdminActivityLog
      )
}

