import React, { useState,useEffect } from 'react'
import { useForm } from "react-hook-form";
import { useSuspendMutation } from '../../../data/users';
import { showToast } from '../../../util/helpers';
import Spinner from '../../../components/common/spinner';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
const suspensionType = ['hours', 'days', 'weeks'];

export default function UserSuspendModel({show,setShow}) {

    const { register, handleSubmit, watch,reset, formState: { errors, } } = useForm();
    const { mutate: suspendMutate, isLoading: fetchloading } = useSuspendMutation();
    const [searchParams,setSearchParams]=useSearchParams()
    const [selectedType,setSelectedType]=useState('')
    
    
    function closeModel(){
        setShow(false)
    }

    function onSubmit(inputs)
    {
        var suspendDate=""
        console.log(selectedType)
        if (selectedType == 'hours') {
            suspendDate = moment().add(inputs.suspend, 'hours').format('MM-DD-YYYY');
          }
          if (selectedType == 'days') {
            suspendDate = moment().add(inputs.suspend, 'days').format('MM-DD-YYYY');
          }
          if (selectedType == 'weeks') {
            suspendDate = moment().add(inputs.suspend, 'weeks').format('MM-DD-YYYY');
          }
      
          const toDay = moment(new Date(), 'MM-DD-YYYY');
          const hours = Math.abs(toDay.diff(suspendDate, 'hours'));
        
          suspendMutate(
                {
                      hours,_id:searchParams.get('id'),
                },
                {
                    onSuccess: (result) => {
                        if(result.data.message=='success'){
                            showToast('User suspend Successful','success')
                            setTimeout(() => {
                                reset('')
                                setShow(false)
                            }, 800);
                        }else{
                            showToast(result.data.message,'error')
                        }
                    }
                })
        
    }

  return (
    <>
        {
            show&&(
                <div className={`modal-backdrop fade in ${show?'show':''}`}></div>
            )
        }
        <Spinner loading={fetchloading}/>
        <div role="dialog" tabIndex="-1" className={`modal fade ${show?'show':''}`} style={{display: (show?'block':'none')}} aria-modal="true">
            <div role="document" className="modal-dialog">
                <form onSubmit={handleSubmit(onSubmit)} className="modal-content">
                    <div className="card m-0">
                        <div className="card-header modelHeader">
                            <div className="row">
                            <div className="col-10 align-self-center">
                                <h5>User Suspend</h5>
                            </div>
                            <div className="col-2 text-right">
                                <i className="fa fa-times cursor" onClick={()=>closeModel()}></i>
                            </div>
                            </div>
                        </div>

                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group">
                                    <label className="col-form-label font-weight-bold required">Suspension Type</label>
                                    <select className="form-control"  onChange={(event)=>setSelectedType(event.target.value)}>
                                    {
                                        suspensionType.map((item,i)=>{
                                            return (
                                                <option key={i} value={item}>{item}</option>

                                            )
                                        })
                                    }
                                    </select>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label className="col-form-label font-weight-bold required">Suspend {selectedType}</label>
                                        <input className="form-control" type="number"  min="1" {...register('suspend',{required:true})} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer modelFooter">
                            <div className="float-right">
                                <button className="btn btn-primary mr-2">
                                    save
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </>
  )
}
