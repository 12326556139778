import { environment } from './environment';

export function getPagination(){
  
  return{
    pageSize :
    localStorage['user'] ?
      (JSON.parse(localStorage['user']) ?
        (JSON.parse(localStorage['user']).preferences ?
          (JSON.parse(localStorage['user']).preferences.pageSize ?
            JSON.parse(localStorage['user']).preferences.pageSize : 10) : 10) : 10) : 10,
    pageSizeList : [10, 25, 50, 100],
    totalRecords : 0,
  } 
}

export const apiConfigration = {
  login: {
    post: environment.baseurl + '/api/login',
    changePsw: environment.baseurl + '/api/changepassword',
  },
  dashboard: {
    get: environment.baseurl + '/api/dashbord',
  },
  admin: {
    alladmins: environment.baseurl + '/api/alladmins',
    post: environment.baseurl + '/api',
    remove: environment.baseurl + '/api/remove',
  },
  advertisement: {
    api: environment.baseurl + '/api/advertisement',
    remove: environment.baseurl + '/api/advertisement/remove',
    toAll: environment.baseurl + '/api/announcements/to-all',
  },
  emailTemp: {
    api: environment.baseurl + '/api/emailtemplate',
    remove: environment.baseurl + '/api/emailtemplate/remove',
    stausChange: environment.baseurl + '/api/emailtemplate/status',
  },
  regions: {
    api: environment.baseurl + '/api/regionsconfig',
  },
  tips: {
    api: environment.baseurl + '/api/tips',
    remove: environment.baseurl + '/api/tips/remove',
    stausChange: environment.baseurl + '/api/tips/status',
  },
  activityTracking: {
    all: environment.baseurl + '/api/activity-tracking/all',
  },
  user: {
    all: environment.baseurl + '/api/user/allusers',
    api: environment.baseurl + '/api/user',
    remove: environment.baseurl + '/api/user/remove-user',
    addMemebership: environment.baseurl + '/api/user/add-memebership',
    removeMemebership: environment.baseurl + '/api/user/remove-memebership',
    addUserNote: environment.baseurl + '/api/user/add-user-note',
    updateIdentity: environment.baseurl + '/api/user/update-identity',
    removeIdentityPic: environment.baseurl + '/api/user/remove-identity-pic',
    removeUserNote: environment.baseurl + '/api/user/remove-user-note',
    suspendUser: environment.baseurl + '/api/user/suspend-user',
    cancelSuspensionUser: environment.baseurl + '/api/user/cancel-suspension-user',
    activity: environment.baseurl + '/api/user/activity',
    searchUsers: environment.baseurl + '/api/user/search-users',
    // uploadIdProof: environment.SOCKET_ENDPOINT + 'update-idcard',
    friends: environment.baseurl + '/api/user/friends-user',
    ignoredFriends: environment.baseurl + '/api/user/ignored-user',
    statistics: environment.baseurl + '/api/user/statistics',
    addUserBadge: environment.baseurl + '/api/user/add-user-badge',
    removeUserBadge: environment.baseurl + '/api/user/remove-user-badge',
    broadcastLog: environment.baseurl + '/api/user/brodcasted-message',
    setBadgePrimary: environment.baseurl + '/api/user/set-badge-primary',
    removeBadgePrimary: environment.baseurl + '/api/user/remove-primary-badge',
    badge: environment.baseurl + '/api/user/badge-users',
  },
  striperLevel: {
    api: environment.baseurl + '/api/striper-level',
    remove: environment.baseurl + '/api/striper-level/remove',
  },
  patronLevel: {
    api: environment.baseurl + '/api/patron-level',
    remove: environment.baseurl + '/api/patron-level/remove',
  },
  gameConfig: {
    api: environment.baseurl + '/api/game-config',
  },
  dimensionConfig: {
    api: environment.baseurl + '/api/dimensionconfig',
    remove: environment.baseurl + '/api/dimensionconfig/remove',
  },
  report: {
    abuseReport: environment.baseurl + '/api/abuse-report',
  },
  badges: {
    api: environment.baseurl + '/api/badges',
    remove: environment.baseurl + '/api/badges/remove',
    sortbadgesorder: environment.baseurl + '/api/badges/update-sortorder',
  },
};
