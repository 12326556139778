import http from "../util/http";
import { useMutation,useQuery } from "react-query";
import { apiConfigration ,getPagination} from "../config/api";
import {transform} from '../util/sortPipe'

async function getBadges(){
    const { data } = await http.get(apiConfigration.badges.api)

    return transform(data.data,'asc', 'sortOrder')
}
export function useBadgesQuery(){
    return useQuery("badges",getBadges)
}
async function updateBadgeSort(input){
    return http.post(apiConfigration.badges.sortbadgesorder,input)
}

export function useUpdateSortOrderMutation(){
    return useMutation('updateBadgeSort',updateBadgeSort)
}

async function getBadgesMember({queryKey}){
    const {id,limit,pageIndex,search,skip} = queryKey[1]
    const {pageSize} = getPagination() 

    const { data } =await http.get(apiConfigration.user.badge+`/${id}?search=${search}&pageIndex=${(pageIndex)}&limit=${limit}&skip=${(pageIndex) * pageSize}`)

    return data;
}

export function useFetchBadgesMemberQuery(options){
    return useQuery(
        ['getBadgesMember', options],
        getBadgesMember
      )
}



export const roleList = [
    { name: 'Admin', value: 'admin' },
    { name: 'Staff', value: 'staff' },
    { name: 'Moderator', value: 'moderator' },
];
function createFormData({color,group,isCheck,name,file}){
    const roles = [];
    roleList.forEach(element => {
      if (element.isCheck) {
        roles.push(element.value);
      }
    });
    const formData = new FormData();
    formData.append('name', name);
    formData.append('roles', isCheck);
    formData.append('color', color);
    formData.append('file', (file || ''));
    formData.append('group', (group));

    return formData;
}
async function createBadge({color,group,isCheck,name,file}){

    const formData=createFormData({color,group,isCheck,name,file})

    return http.post(apiConfigration.badges.api,formData)
}
export function useCreatBadgeMutation(){
    return useMutation('createBadge',createBadge)
}

async function updateBadge({color,group,isCheck,name,file,id}){
    const formData=createFormData({color,group,isCheck,name,file})

    return http.post(`${apiConfigration.badges.api}/${id}`,formData)
}
export function useUpdateBadgeMutation(){
    return useMutation('updateBadge',updateBadge)
}

async function deleteBadge({id}){
    return http.get(`${apiConfigration.badges.remove}/${id}`)
}
export function useDeleteBadgesMuatation(){
    return useMutation('deleteBadge',deleteBadge)
}