import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import { useAddEmailTemplateMutation ,emailType} from '../../data/emailTemplate';
import { showToast } from '../../util/helpers';
import { environment } from '../../config/environment';
import EmailUserSelectModel from './emailUserSelectModel';
import Spinner from '../common/spinner';


export default function AddEditModel({show,setShow,setLoading,refetch}) {
    const [showUserList,setShowUserList]= useState([])
    const [selectedUserList,setSelectedUserList]= useState('')

    const [modelShow2,setModelShow2]=useState(false)

    const { register, handleSubmit, watch,reset, formState: { errors, } } = useForm();
    const { mutate: addEmailTemplateMutate, isLoading: fetchloading } = useAddEmailTemplateMutation();
    
    function closeModel(){
        setShow(false)
    }

    function onSubmit(inputs)
    {
        setLoading(true)
        if(selectedUserList.length > 0){
            var to=selectedUserList.map(m=>m._id)
        }else{
            var to="all"
        }
            addEmailTemplateMutate(
                {
                    ...inputs,to:to,
                },
                {
                    onSuccess: (result) => {
                        setLoading(false)
                        if(result.data.message=='success'){
                            showToast('Announcement Successfully added','success')
                            setTimeout(() => {
                                reset('')
                                setShow(false)
                            }, 800);
                        }else{
                            showToast(result.data.message,'error')
                        }
                        refetch()
                    }
                })
        
    }
    function selectUser(){
        setModelShow2(true)
    }
    function removeUser(item){
        setSelectedUserList(showUserList.filter(i=>i._id!=item._id))
    }
    function setDefaultImage(event){
        event.target.src="/images/no-profile-image.jpg"
    }

  return (
    <>
        {
            show&&(
                <div className={`modal-backdrop fade in ${show?'show':''}`}></div>
            )
        }
        <Spinner loading={fetchloading}/>
        <div role="dialog" tabIndex="-1" className={`modal fade ${show?'show':''}`} style={{display: (show?'block':'none')}} aria-modal="true">
            <div role="document" className="modal-dialog">
                <form onSubmit={handleSubmit(onSubmit)} className="modal-content">
                    <div className="card m-0">
                        <div className="card-header modelHeader">
                            <div className="row">
                                <div className="col-10 align-self-center">
                                    <h5>Email</h5>
                                </div>
                                <div className="col-2 text-right">
                                    <i className="fa fa-times cursor" onClick={()=>closeModel()}></i>
                                </div>
                            </div>
                        </div>

                        <div className="card-body">
                            <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="col-form-label font-weight-bold required">Title</label>
                                    <input className="form-control" type="text" placeholder="Enter title" {...register('title',{require:true})} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="col-form-label font-weight-bold required">Message</label>
                                    <input className="form-control" type="text" placeholder="Enter message" {...register('msg',{require:true})} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                <label className="col-form-label font-weight-bold required">Type</label>
                                <div className="col-form-label d-flex flex-wrap">
                                    {
                                        emailType?.map((item,i)=>{
                                            return (            
                                                <div className="form-check mr-2" key={i}>
                                                    <input className="form-check-input" type="radio" name="typerdios" value={item.value}  {...register('type',{require:true})} />
                                                    <label className="form-check-label">{item.name}</label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="col-form-label font-weight-bold required">Send To</label>
                                    <div className="col-form-label d-flex flex-wrap">
                                        <div className="form-check mr-2">
                                            <input className="form-check-input" type="radio" value="false" checked={showUserList=='false'} onChange={(event)=>setShowUserList(event.target.value)}/>
                                            <label className="form-check-label">All</label>
                                        </div>
                                        <div className="form-check mr-2">
                                            <input className="form-check-input" type="radio" value="true" checked={showUserList=='true'}  onChange={(event)=>setShowUserList(event.target.value)} />
                                            <label className="form-check-label">Select From List</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                showUserList&&(
                                    <div className="col-12">
                                        <button className="btn btn-light" type="button" onClick={()=>selectUser()}>Select member</button>
                                    </div>
                                )
                            }
                            {
                                (showUserList && selectedUserList?.length> 0)&&(
                                    <div className="col-12 mt-2">
                                        <label className="col-form-label font-weight-bold">Selected Member List</label>

                                        <table className="table table-striped table-responsive-md">
                                            <thead>
                                                <tr>
                                                <th className="text-center">Profile</th>
                                                <th>Member ID</th>
                                                <th>Member Name</th>
                                                <th>Screen Name</th>
                                                <th>Email</th>
                                                <th>Dob</th>
                                                <th style={{width: '5%'}} className="text-center">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    selectedUserList?.map((item,i)=>{
                                                        return (
                                                            <tr key={i}>
                                                                <td className="text-center">
                                                                    <img className="profile cursor" src={environment.SOCKET_ENDPOINT+item.pp} alt="" onError={setDefaultImage}/>
                                                                </td>
                                                                <td>{item?._id}</td>
                                                                <td>{item?.un}</td>
                                                                <td>{item?.sn}</td>
                                                                <td>{item?.email}</td>
                                                                <td>{item?.dob}</td>
                                                                <td className="text-center">
                                                                    <i className="fa fa-trash cursor" onClick={()=>removeUser(item)}></i>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                                
                                            </tbody>
                                        </table>
                                    </div>
                                )
                            }
                            
                            </div>
                        </div>

                        <div className="card-footer modelFooter">
                            <div className="float-right">
                                <button className="btn btn-primary mr-2" >Save</button>
                            </div>
                        </div>
                    </div>

                </form>
            </div>
            {
                modelShow2&&(
                    <EmailUserSelectModel show={modelShow2} setShow={()=>setModelShow2()} sendSelectedUserList={(value)=>setSelectedUserList(value)}/>
                )
            }
        </div>
    </>
  )
}
