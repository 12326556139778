import React, { useState } from 'react'
import DefaultLayout from '../../layouts/DefaultLayout'
import {useFetchAllUsersQuery} from '../../data/users'
import { useSearchParams } from 'react-router-dom'
import { getPagination} from "../../config/api";
import Pagination from '../../components/common/pagination'
import {environment} from '../../config/environment'
import Spinner from '../../components/common/spinner';
import { useNavigate } from 'react-router-dom';
import AddEditModel from '../../components/users/addEditModel';
import { checkPermission } from '../../util/helpers';
import { permissionList } from '../../data/permission-list';

export default function Users() {
    const navigate = useNavigate();
    const [search,setSearch]= useState('')
    const [pageIndex,setPageIndex]= useState(0)
    const [limit,setLimit]= useState(25)
    const [skip,setSkip]= useState(0)
    const [selectedUser,setSelectedUser] =useState('')

    const [modelShow,setModelShow]=useState(false)
    const [loading,setLoading] =useState('')

    const [searchParams,setSearchParams]=useSearchParams()
    const { data:dataList, isLoading: fetchloading ,refetch}  = useFetchAllUsersQuery({search:(search?search:(searchParams.get('search')?searchParams.get('search'):'')),pageIndex,limit,skip,id:searchParams.get('id')})

    function setDefaultImage(event){
        event.target.src="/images/no-profile-image.jpg"
    }
    function resetData(){
        document.querySelector('#search').value=''
        setSearch('')
        refetch()
    }
    function addEditUser(){
        setModelShow(true)
    }
    
    function ViewMemberDetails(item){
        navigate('/members/detail/overview?id='+item._id)
    }
  return (
    <DefaultLayout>
        <Spinner loading={fetchloading||loading}/>

        <div className="row">
            <div className="col-md-3">
                <div className="input-group">
                    <div className="input-group-prepend" onClick={()=>refetch()}>
                        <span className="input-group-text"><i className="fa fa-search"></i></span>
                    </div>
                    <input type="text" className="form-control" placeholder="Search" id="search" onChange={(event)=>setSearch(event.target.value)} />
                            {/* (keyup.enter)="" */}
                        {/* [(ngModel)]="pagination.search" */}
                    <button type="button" className="btn btn-primary ml-2" onClick={()=>resetData()}>Clear</button>
                </div>
            </div>
            {
                checkPermission(permissionList.AddMember)&&(

                    <div class="col-md-9 text-md-right mt-2 mt-md-0">
                        <button  type="button" class="btn btn-primary" onClick={()=>addEditUser()}>
                        <i class="fa fa-plus"></i> Add
                        </button>
                    </div>
                )
            }

        </div>
        <div className="row center-content">
            <div className="col-12">
                <table className="table table-striped table-responsive-md my-3">
                <thead>
                    <tr>
                    <th className="text-center">Profile</th>
                    <th>Member ID</th>
                    <th>Member Name</th>
                    <th>Email</th>
                    <th>Dob</th>
                    <th>Membership</th>
                    <th>Gender</th>
                    <th>Status</th>
                    </tr>
                </thead>
                <tbody >
                    {
                        dataList?.data?.map((item,i)=>{
                            return (
                                <tr key={i}>
                                    <td className="text-center">
                                        <a href={environment.webLobbyUrl+'profile?memberId='+item._id} target="blank">
                                        <img className="profile cursor" src={environment.SOCKET_ENDPOINT+item.pp} alt=""
                                            onError={setDefaultImage}/>
                                        </a>
                                    </td>
                                    <td>
                                        <a className="custom-a" href="javascript:void(0)" onClick={()=>ViewMemberDetails(item)}>
                                        {item?._id}
                                        </a>
                                    </td>
                                    <td>
                                        <a className="custom-a" href="javascript:void(0)" onClick={()=>ViewMemberDetails(item)}>
                                        {item?.un}
                                        </a>
                                    </td>
                                    <td>{item?.email}</td>
                                    <td>{item?.dob}</td>
                                    <td>{item?.membership}</td>
                                    <td>{item?.gender}</td>
                                    <td>
                                        {
                                            item.isOnline===1&&(
                                                <span className="badge bg-success">Online</span>
                                            )
                                        }
                                        {
                                            item.isOnline===0&&(
                                                <span className="badge bg-danger">Offline</span>
                                            )
                                        }
                                    </td>
                                </tr>
                            )
                        })
                    }
                    
                </tbody>
                </table>
            </div>
            <div className="col-12">
                <Pagination total={dataList?.count} pageIndex={pageIndex} pageSize={getPagination().pageSize} setPageIndex={setPageIndex} setPageSize={setLimit}/>
            </div>
            {
                modelShow&&(
                    <AddEditModel user={selectedUser} show={modelShow} setShow={()=>setModelShow()} setLoading={(value)=>setLoading(value)} refetch={()=>refetch()}/>
                )
            }
        </div>
    </DefaultLayout>
  )
}
