import React, { useState,useEffect } from 'react'
import { useForm } from "react-hook-form";
import { useAddEditAdminMutation,roleList,statusList} from '../../data/adminList';
import { showToast } from '../../util/helpers';
import Spinner from '../common/spinner';
import AccessListModel from './AccessListModel';


export default function AddEditModel({admin,show,setShow,setLoading,refetch}) {

    const [modelShow,setModelShow]=useState(false)
    const [permissionList,setPermissionList]=useState(false)

    

    const { register, handleSubmit, watch,reset, formState: { errors, } } = useForm();
    const { mutate: addEditAdminMutate, isLoading: fetchloading } = useAddEditAdminMutation();

    useEffect(()=>{
        if(admin?.name){
            reset(getDefaultValues())
        }
    },[admin?.name])

    function getDefaultValues(){
        return {
            name:admin?.name,
            email:admin?.email,
            role:admin?.role,
            active:admin?.active,
            id:admin?._id,
        }  
    }
    
    function closeModel(){
        setShow(false)
    }

    function onSubmit(inputs)
    {
        setLoading(true)
        
        addEditAdminMutate(
            {
                ...inputs,access:permissionList
            },
            {
                onSuccess: (result) => {
                    setLoading(false)
                    if(result.data.message=='success'){
                        showToast('Dimension Successfully stored','success')
                        setTimeout(() => {
                            reset('')
                            setShow(false)
                        }, 800);
                    }else{
                        showToast(result.data.message,'error')
                    }
                    refetch()
                }
            }
        )
        
    }

  return (
    <>
        {
            show&&(
                <div className={`modal-backdrop fade in ${show?'show':''}`}></div>
            )
        }
        <Spinner loading={fetchloading}/>
        <div role="dialog" tabIndex="-1" className={`modal fade ${show?'show':''}`} style={{display: (show?'block':'none')}} aria-modal="true">
            <div role="document" className="modal-dialog">
                <form onSubmit={handleSubmit(onSubmit)} className="modal-content">
                    <div className="card m-0">
                        <div className="card-header modelHeader">
                            <div className="row">
                            <div className="col-10 align-self-center">
                                <h5>{admin ? 'Add' : 'Edit'} Admin</h5>
                            </div>
                            <div className="col-2 text-right">
                                <i className="fa fa-times cursor" onClick={()=>closeModel()}></i>
                            </div>
                            </div>
                        </div>

                        <div className="card-body">
                            <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                <label className="col-form-label font-weight-bold required">Name</label>
                                <input className="form-control" type="text" placeholder="Enter name" {...register('name',{required:true})}/>
                                </div>
                            </div>
                            <input className="form-control" type="hidden" defaultValue={admin?._id} {...register('_id')}/>

                            <div className="col-12">
                                <div className="form-group">
                                <label className="col-form-label font-weight-bold required">Email</label>
                                <input className="form-control" type="text" placeholder="Enter email" {...register('email',{required:true})}/>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                <label className="col-form-label font-weight-bold">Password</label>
                                <input className="form-control" type="password" placeholder="Enter password" {...register('password',{required:true})}/>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                <label className="col-form-label font-weight-bold">Confirm Password</label>
                                <input className="form-control" type="password" placeholder="Enter confirm password" {...register('confirmPassword',{required:true})}/>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                <label className="col-form-label font-weight-bold required">Role</label>
                                <div className="col-form-label d-flex flex-wrap">
                                    {
                                        roleList.map((item,i)=>{
                                            return (
                                                <div key={i} className="form-check mr-2">
                                                    <input className="form-check-input" type="radio" name="roleradios" value={item.value} {...register('role')}/>
                                                    <label className="form-check-label">{item.name}</label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <button className="btn btn-light" type='button' onClick={()=>setModelShow(true)}>
                                    {admin ? 'Add' : 'Update'} Access
                                </button>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                <label className="col-form-label font-weight-bold required">Status</label>
                                <div className="col-form-label d-flex flex-wrap">
                                    {
                                        statusList.map((item,i)=>{
                                            return (
                                                <div key={i} className="form-check mr-2">
                                                    <input className="form-check-input" type="radio" name="statusradios" value={item.value} {...register('active')}/>
                                                    <label className="form-check-label">{item.name}</label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>

                        <div className="card-footer modelFooter">
                            <div className="float-right">
                                <button className="btn btn-primary mr-2">
                                    {admin ? 'Save' : 'Update'}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            {
                modelShow&&(
                    <AccessListModel oldList={admin?.access?admin?.access:[]}  show={modelShow} setShow={()=>setModelShow()} sendPermissionList={(value)=>setPermissionList(value)}/>
                )
            }
        </div>
    </>
  )
}
