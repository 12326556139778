import React, { useState } from 'react'
import DefaultLayout from '../../layouts/DefaultLayout'
import {useFetchReportQuery} from '../../data/report.js'
import { getPagination} from "../../config/api";
import Pagination from '../../components/common/pagination'
import Spinner from '../../components/common/spinner';

export default function Report() {
    const [search,setSearch]= useState('')
    const [pageIndex,setPageIndex]= useState(0)
    const [limit,setLimit]= useState(25)
    const [skip,setSkip]= useState(0)

    const { data:dataList, isLoading: fetchloading ,refetch}  = useFetchReportQuery({search,pageIndex,limit,skip})

    function resetData(){
        document.querySelector('#search').value=''
        setSearch('')
        refetch()
    }
    
  return (
    <DefaultLayout>
        <Spinner loading={fetchloading}/>

        <div className="row">
            <div className="col-md-3">
                <div className="input-group">
                    <div className="input-group-prepend" onClick={()=>refetch()}>
                        <span className="input-group-text"><i className="fa fa-search"></i></span>
                    </div>
                    <input type="text" className="form-control" placeholder="Search" id="search" onChange={(event)=>setSearch(event.target.value)} />
                    <button type="button" className="btn btn-primary ml-2" onClick={()=>resetData()}>Clear</button>
                </div>
            </div>
        </div>
        <div className="row center-content">
            <div className="col-12">
                <table className="table table-striped table-responsive-md my-3">
                    <thead>
                        <tr>
                            <th>Description</th>
                            <th>From User</th>
                            <th>To User</th>
                            <th>Reason</th>
                        </tr>
                    </thead>
                    <tbody >
                        {
                            dataList?.data?.map((item,i)=>{
                                return (
                                    <tr key={i}>
                                        <td>{item?.description}</td>
                                        <td>{item?.from_user}</td>
                                        <td>{item?.to_user}</td>
                                        <td>{item?.reson}</td>
                                    </tr>
                                )
                            })
                        }
                        
                    </tbody>
                </table>
            </div>
            <div className="col-12">
                <Pagination total={dataList?.count} pageIndex={pageIndex} pageSize={getPagination().pageSize} setPageIndex={setPageIndex} setPageSize={setLimit}/>
            </div>
        </div>
    </DefaultLayout>
  )
}
