import http from "../util/http";
import { useMutation,useQuery } from "react-query";
import { apiConfigration ,getPagination} from "../config/api";
import { permissionList } from './permission-list';

async function getAllAdmins({queryKey}){
    const {limit,pageIndex,search} = queryKey[1]
    const {pageSize} = getPagination() 

    const { data } =await http.get(apiConfigration.admin.alladmins+`?search=${search}&pageIndex=${(pageIndex)}&limit=${limit}&skip=${(pageIndex) * pageSize}`)

    return data;
}


export function useFetchAllAdminsQuery(options){
    return useQuery(
        ['getAllAdmins', options],
        getAllAdmins
      )
}

async function addEditAdmin(input){
    const {_id}=input
    if(_id){
        return http.post(`${apiConfigration.admin.post}/${_id}`, input)
    }
    return http.post(`${apiConfigration.admin.post}`, input)
}
export function useAddEditAdminMutation(){
    return useMutation('addEditAdmin',addEditAdmin)
}

export const roleList = [
  { name: 'Admin', value: 'admin' },
  { name: 'Staff', value: 'staff' },
  { name: 'Moderator', value: 'moderator' },
];
export const statusList = [
    { name: 'Active', value: 1 },
    { name: 'Inactive', value: 0 },
  ];


 export const totalPermissionList= [
    { name: 'View Change Password', code: permissionList.ViewChangePassword },
    { name: 'View Change Preferences', code: permissionList.ViewChangePreferences },

    { name: 'View Manage Admins', code: permissionList.ViewManageAdmins },
    { name: 'Add Admin', code: permissionList.AddAdmin },
    { name: 'Update Admin', code: permissionList.UpdateAdmin },
    { name: 'Delete Admin', code: permissionList.DeleteAdmin },

    { name: 'View Member', code: permissionList.ViewMember },
    { name: 'Add Member', code: permissionList.AddMember },
    { name: 'Update Member', code: permissionList.UpdateMember },
    { name: 'Delete Member', code: permissionList.DeleteMember },
    { name: 'View Member Detail', code: permissionList.ViewMemberDetail },
    { name: 'Suspend Member', code: permissionList.SuspendMember },

    { name: 'Add World Broadcast', code: permissionList.AddWorldBroadcast },
    { name: 'View Announcements', code: permissionList.ViewAnnouncements },
    { name: 'Add Announcements', code: permissionList.AddAnnouncements },
    { name: 'Update Announcements', code: permissionList.UpdateAnnouncements },
    { name: 'Delete Announcements', code: permissionList.DeleteAnnouncements },

    { name: 'View Private Mail & Email', code: permissionList.ViewEmailTemplate },
    { name: 'Add Private Mail & Email', code: permissionList.AddEmailTemplate },
    { name: 'Delete Private Mail & Email', code: permissionList.DeleteEmailTemplate },

    { name: 'View Regions', code: permissionList.ViewRegions },
    { name: 'Update Regions', code: permissionList.UpdateRegions },
    { name: 'View Dimension', code: permissionList.ViewDimension },
    { name: 'Add Dimension', code: permissionList.AddDimension },
    { name: 'Update Dimension', code: permissionList.UpdateDimension },
    { name: 'Delete Dimension', code: permissionList.DeleteDimension },

    { name: 'View Admin Activity', code: permissionList.ViewAdminActivity },
    { name: 'View Game Config', code: permissionList.ViewGameConfig },
    { name: 'Update Game Config', code: permissionList.UpdateGameConfig },
    { name: 'View Abuse Report', code: permissionList.ViewAbuseReport },

    { name: 'View Badges', code: permissionList.ViewBadges },
    { name: 'Add Badges', code: permissionList.AddBadges },
    { name: 'Update Badges', code: permissionList.UpdateBadges },
    { name: 'Delete Badges', code: permissionList.DeleteBadges },

    { name: 'Add User Badges', code: permissionList.AddUserBadges },
    { name: 'Delete User Badges', code: permissionList.DeleteUserBadges },

    { name: 'View Broadcast Log', code: permissionList.ViewBroadcastLog },
  ];
