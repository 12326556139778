import React, { useEffect, useState } from 'react'
import { useFetchDimensionsQuery } from '../../../data/region';
import { getPagination,apiConfigration} from "../../../config/api";
import Pagination from '../../../components/common/pagination'
import Spinner from '../../common/spinner';
import { showToast,checkPermission } from '../../../util/helpers';
import { permissionList } from '../../../data/permission-list';
import AddEditModel from './addEditModel';
import DeleteConfirm from '../../common/deleteConfirm';

export default function Dimension({show,setShow,roomsConfigId}) {
    const [search,setSearch]= useState('')
    const [pageIndex,setPageIndex]= useState(0)
    const [limit,setLimit]= useState(25)
    const [skip,setSkip]= useState(0)
    const [selectedDimension,setSelectedDimension] =useState('')

    const [modelShow,setModelShow]=useState(false)
    const [loading,setLoading] =useState('')
    const { data:dataList, isLoading: fetchloading ,refetch:refetch}  = useFetchDimensionsQuery({search,pageIndex,limit,skip,roomsConfigId:roomsConfigId})
    
    function closeModel(){
        setShow(false)
    }
    function editDimension(item){
        setModelShow(true)
        setSelectedDimension(item)
    }
    function addDimension(){
        setSelectedDimension('')
        setModelShow(true)
    }


  return (
    <>
        {
            show&&(
                <div className={`modal-backdrop fade in ${show?'show':''}`}></div>
            )
        }
        <Spinner loading={fetchloading||loading}/>
        <div role="dialog" tabIndex="-1" className={`modal fade ${show?'show':''}`} style={{display: (show?'block':'none')}} aria-modal="true">
            <div role="document" className="modal-dialog">
                <div className="modal-content">
                    <div className="card m-0">
                        <div className="card-header modelHeader">
                            <div className="row">
                            <div className="col-10 align-self-center">
                                <h5>Dimension Detail</h5>
                            </div>
                            <div className="col-2 text-right">
                                <i className="fa fa-times cursor" onClick={()=>closeModel()}></i>
                            </div>
                            </div>
                        </div>

                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12 text-md-right">
                                    {
                                        checkPermission(permissionList.AddDimension)&&(
                                            <button type="button" className="btn btn-primary" onClick={()=>addDimension()}>
                                                <i className="fa fa-plus"></i> Add
                                            </button>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <table className="table table-striped table-responsive-md my-3">
                                    <thead>
                                        <tr>
                                            <th>Language</th>
                                            <th>Performer Gender</th>
                                            <th>Avatar Gender</th>
                                            <th>Avatar Size</th>
                                            <th>Type</th>
                                            <th>Membership Level</th>
                                            <th>Population</th>
                                            <th>Online Player</th>
                                            <th style={{width: '8%'}} className="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            dataList?.data?.map((item,i)=>{
                                                return(
                                                    <tr key={i}>
                                                        <td>{item?.Language}</td>
                                                        <td>{item?.PerformerGender}</td>
                                                        <td>{item?.AvatarGender}</td>
                                                        <td>{item?.AvatarSize}</td>
                                                        <td>{item?.Type}</td>
                                                        <td>{item?.PlayerMembershipLevel}</td>
                                                        <td>{item?.population}</td>
                                                        <td>{item?.dim_uuser_count}</td>
                                                        <td className="text-center">
                                                            {
                                                                checkPermission(permissionList.UpdateDimension)&&(
                                                                    <i className="fa fa-pencil cursor" tooltip="Edit" onClick={()=>editDimension(item)}></i>
                                                                )
                                                            }
                                                            {
                                                                checkPermission(permissionList.DeleteDimension)&&(
                                                                    <DeleteConfirm name="Dimension" url={`${apiConfigration.dimensionConfig.remove}/${item?._id}`} refetch={()=>refetch()} setLoading={(value)=>setLoading(value)}/>
                                                                    // <i className="fa fa-trash cursor" placement="left"></i>
                                                                )
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                    </table>
                                </div>
                                <div className="col-12">
                                    <Pagination total={dataList?.count} pageIndex={pageIndex} pageSize={getPagination().pageSize} setPageIndex={setPageIndex} setPageSize={setLimit}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                modelShow&&(
                    <AddEditModel roomsConfigId={roomsConfigId} dimension={selectedDimension} show={modelShow} setShow={()=>setModelShow()} setLoading={(value)=>setLoading(value)} refetch={()=>refetch()}/>
                )
            }
        </div>
    </>
  )
}
