import React, { useState,useEffect } from 'react'
import { useForm } from "react-hook-form";
import { useAddEditUserMutation ,genderList} from '../../data/users';
import { showToast } from '../../util/helpers';
import Spinner from '../common/spinner';


export default function AddEditModel({user,show,setShow,setLoading,refetch}) {

    const { register, handleSubmit, watch,reset, formState: { errors, } } = useForm();
    const { mutate: addEdituserMutate, isLoading: fetchloading } = useAddEditUserMutation();

    // useEffect(()=>{
    //     if(user?.room){
    //         reset(getDefaultValues())
    //     }
    // },[user?.room])

    // function getDefaultValues(){
    //     return {
    //         id:user?._id,
    //         room:user?.room,
    //         cover_charge:user?.cover_charge,
    //         streaming_link:user?.streaming_link
    //     }  
    // }
    
    function closeModel(){
        setShow(false)
    }

    function onSubmit(inputs)
    {
        setLoading(true)
        
            addEdituserMutate(
                {
                    ...inputs,
                },
                {
                    onSuccess: (result) => {
                        setLoading(false)
                        if(result.data.message=='success'){
                            showToast('users Successfully stored','success')
                            setTimeout(() => {
                                reset('')
                                setShow(false)
                            }, 800);
                        }else{
                            showToast(result.data.message,'error')
                        }
                        refetch()
                    }
                })
        
    }

  return (
    <>
        {
            show&&(
                <div className={`modal-backdrop fade in ${show?'show':''}`}></div>
            )
        }
        <Spinner loading={fetchloading}/>
        <div role="dialog" tabIndex="-1" className={`modal fade ${show?'show':''}`} style={{display: (show?'block':'none')}} aria-modal="true">
            <div role="document" className="modal-dialog">
                <form onSubmit={handleSubmit(onSubmit)} className="modal-content">
                    <div className="card m-0">
                        <div className="card-header modelHeader">
                            <div className="row">
                            <div className="col-10 align-self-center">
                                <h5>{!user ? 'Add' : 'Edit'} user</h5>
                            </div>
                            <div className="col-2 text-right">
                                <i className="fa fa-times cursor" onClick={()=>closeModel()}></i>
                            </div>
                            </div>
                        </div>

                        <div className="card-body">
                            <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                <label className="col-form-label font-weight-bold required">Email</label>
                                <input className="form-control" type="text" placeholder="Enter email" {...register('email',{required:true})}/>
                                </div>
                            </div>
                            {
                                !user&&(
                                    <div className="col-md-6">
                                        <div className="form-group">
                                        <label className="col-form-label font-weight-bold required">Password</label>
                                        <input className="form-control" type="password" placeholder="Enter password" {...register('pass',{required:true})}/>
                                        </div>
                                    </div>
                                )
                            }
                            <div className="col-md-6">
                                <div className="form-group">
                                <label className="col-form-label font-weight-bold required">Member Name</label>
                                <input className="form-control" type="text" placeholder="Enter member name" {...register('un',{required:true})}/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                <label className="col-form-label font-weight-bold required">Screen Name</label>
                                <input className="form-control" type="text" placeholder="Enter screen name" {...register('sn',{required:true})}/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                <label className="col-form-label font-weight-bold required">DOB</label>
                                <input className="form-control" type="date" {...register('dob',{required:true})} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                <label className="col-form-label font-weight-bold required">Gender</label>
                                <div className="col-form-label d-flex flex-wrap">
                                    {
                                        genderList.map((item,i)=>{
                                            return(
                                                <div className="form-check mr-2">
                                                <input className="form-check-input" type="radio" name="typeradios" value={item} {...register('gender',{required:true})}/>
                                                <label className="form-check-label text-capitalize">{item}</label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>

                        <div className="card-footer modelFooter">
                            <div className="float-right">
                                <button className="btn btn-primary mr-2">
                                    {!user ? 'Save' : 'Update'}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </>
  )
}
