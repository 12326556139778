import http from "../util/http";

import { useMutation,useQuery } from "react-query";

export const graphList = [
    { type: 1, name: 'Daily Active Member Report' },
    { type: 2, name: 'Monthly Active Member Report' },
    { type: 3, name: 'Member Last 30 Days' },
    { type: 4, name: 'Monthly Uses Report' },
    { type: 5, name: 'Total Booty Spent' },
  ];

async function getDashboard(){
    const { data } = await http.get('http://167.99.91.36:2020/api/dashbord')

    return data.data
}
export function useDashboardQuery(){
    return useQuery("dashboard",getDashboard)
}
