import http from "../util/http";
import { useMutation,useQuery } from "react-query";
import { apiConfigration } from "../config/api";

async function getStatistic({queryKey}){
    const {id} = queryKey[1]

    const { data } = await http.get(`${apiConfigration.user.statistics}/${id}`)

    return data.data;
}

export function useFetchStatisticQuery(options){
    return useQuery(
        ['getStatistic', options],
        getStatistic
      )
}