import { useState } from 'react'
import { useNavigate } from 'react-router';
import Cookies from 'js-cookie';
export default function Dropdown({setModelShow,setModelShow2}) {
    const navigate=useNavigate()
    const [show,setShow]=useState(false)
    function hide(){
        setTimeout(() => {
            setShow(false)
        }, 600);
    }
    function logout(){
        window.localStorage.clear();
        Cookies.remove('token')
        navigate('/login');
    }
    return (
        <li  placement="bottom right" className={`nav-item dropdown open ${show?'show':''}`}>
            <a  onClick={()=>setShow(true)} data-toggle="dropdown" onBlur={()=>hide()} href="#" role="button" aria-haspopup="true" dropdowntoggle="" className="nav-link" aria-expanded="true">
                <img src="/images/avatar.png" alt="Admin" className="img-avatar" />
            </a>
            {
                show?(<div aria-labelledby="simple-dropdown" className={`dropdown-menu dropdown-menu-right ng-star-inserted ${show?'show':''}`} >
                <div className="dropdown-header text-center"><strong>Admin</strong></div>
                <a className="dropdown-item cursor" onClick={()=>setModelShow(true)}>
                  <i className="fa fa-wrench"></i> Change Password
                </a>
                <a className="dropdown-item cursor" onClick={()=>setModelShow2(true)}>
                 <i className="fa fa-cog"></i> Preferences
                </a>
                <a routerlink="/admin-list" className="dropdown-item cursor" href="/admin-list" onClick={()=>navigate('/admin-list')}>
                 <i className="fa fa-cogs"></i> Manage Admins
                </a>
                <a className="dropdown-item cursor" onClick={()=>logout()}><i className="fa fa-lock"></i> Logout</a>
            </div>):""
            }
            
        </li>
    )
}
