import React, { useState } from 'react'
import DefaultLayout from '../../layouts/DefaultLayout'
import {useFetchAdminActivityQuery} from '../../data/adminActivities.js'
import { useSearchParams } from 'react-router-dom'
import { getPagination} from "../../config/api";
import Pagination from '../../components/common/pagination'
import Spinner from '../../components/common/spinner';
import moment from 'moment';

export default function AdminActivity() {
    const [search,setSearch]= useState('')
    const [pageIndex,setPageIndex]= useState(0)
    const [limit,setLimit]= useState(25)
    const [skip,setSkip]= useState(0)

    const [searchParams,setSearchParams]=useSearchParams()
    const { data:dataList, isLoading: fetchloading ,refetch}  = useFetchAdminActivityQuery({search,pageIndex,limit,skip})

    function resetData(){

    }
    function addEditAdminActivity(){

    }
  return (
    <DefaultLayout>
        <Spinner loading={fetchloading}/>
        
        <div className="row">
            <div className="col-md-3">
                <div className="input-group">
                    <div className="input-group-prepend" onClick={()=>refetch()}>
                        <span className="input-group-text"><i className="fa fa-search"></i></span>
                    </div>
                    <input type="text" className="form-control" placeholder="Search" id="search" onKeyDown={(event)=>setSearch(event.target.value)} />
                    <button type="button" className="btn btn-primary ml-2" onClick={()=>resetData()}>Clear</button>
                </div>
            </div>
        </div>
        <div className="row center-content">
            <div className="col-12">
                <table className="table table-striped table-responsive-md my-3">
                    <thead>
                        <tr>
                        <th>Date / Time</th>
                        <th>Admin Name</th>
                        <th>Member Name</th>
                        <th>Activity</th>
                        </tr>
                    </thead>
                    <tbody >
                        {
                            dataList?.data?.map((item,i)=>{
                                return (
                                    <tr key={i}>
                                        {/* | date:'' */}
                                        <td>{moment(item?.createdAt).format('DD-MMM-yyyy hh:mm:ss a') }</td>
                                        <td>{item?.name}</td>
                                        <td>{item?.un}</td>
                                        <td>{item?.activity}</td>
                                    </tr>
                                )
                            })
                        }
                        
                    </tbody>
                </table>
            </div>
            <div className="col-12">
                <Pagination total={dataList?.count} pageIndex={pageIndex} pageSize={getPagination().pageSize} setPageIndex={setPageIndex} setPageSize={setLimit}/>
            </div>
        </div>
    </DefaultLayout>
  )
}
