import React, { useState,useEffect } from 'react'
import DefaultLayout from '../../layouts/DefaultLayout'
import {useGameConfigQuery,useUpdateGameConfigMutation} from '../../data/game-config.js'
import '../../assets/css/game-config.css'
import { showToast } from '../../util/helpers'
import Spinner from '../../components/common/spinner';


export default function GameConfig() {
  const [patronLevel, setPatronLevel] = useState()
  const [performerLevel, setPerformerLevel] = useState()
  const [avatarLevel, setAvatarLevel] = useState()
  const [editType, setEditType] = useState('')
  const {mutate:updateGameConfigMutate , loading:updateloading}=useUpdateGameConfigMutation()
  const { data, isLoading: fetchloading ,refetch}  = useGameConfigQuery()
  useEffect(() => {
      if(data?.patronLevelIncPercentage){
        document.querySelector('#patronLevel').value=data?.patronLevelIncPercentage
        setPatronLevel(data?.patronLevelIncPercentage)
      }
      if(data?.performerLevelIncPercentage){
        document.querySelector('#performerLevel').value=data?.performerLevelIncPercentage
        setPerformerLevel(data?.performerLevelIncPercentage)
      }
      if(data?.avatarLevelIncPercentage){
        document.querySelector('#avatarLevel').value=data?.performerLevelIncPercentage
        setAvatarLevel(data?.avatarLevelIncPercentage)
      }
  }, [data]);
  function onSubmit(value,type){
    var data={
      patronLevelIncPercentage:type==='patron'?value:patronLevel,
      performerLevelIncPercentage:type==='performer'?value:performerLevel,
      avatarLevelIncPercentage:type==='avatar'?value:avatarLevel,
    }
    updateGameConfigMutate(
      { ...data },
      {
        onSuccess: (result) => {
          if(result.status){
            showToast('Game Config Successfully updated!','success')
            setEditType('')
            refetch()
          }
        },
        onError: (error) => {
          console.log(error.message);
        }
      })
  }

  return (
    <DefaultLayout>
      <Spinner loading={fetchloading||updateloading}/>
      
      <section className="page-contain">
        <div className="data-card col-lg-3 col-md-4">
          <h3>{data?.patronLevelIncPercentage}</h3>
          <h4>Patron Level Percentage</h4>
          <div className={`view-edit ${editType=='patron'?'view-edit-active':''}`}>
            <div className="form-group">
              <input className="form-control" type="number" max="100" id="patronLevel" min="1" placeholder="Enter patron level" 
                onBlur={((event)=>onSubmit(event.target.value,'patron'))} 
                onKeyDown={(event)=>setPatronLevel(event.target.value)}
              />
            </div>
          </div>
          <span className="link-text" onClick={()=>setEditType('patron')}>
            Click here to edit
            <svg width="25" height="16" viewBox="0 0 25 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd"
                d="M17.8631 0.929124L24.2271 7.29308C24.6176 7.68361 24.6176 8.31677 24.2271 8.7073L17.8631 15.0713C17.4726 15.4618 16.8394 15.4618 16.4489 15.0713C16.0584 14.6807 16.0584 14.0476 16.4489 13.657L21.1058 9.00019H0.47998V7.00019H21.1058L16.4489 2.34334C16.0584 1.95281 16.0584 1.31965 16.4489 0.929124C16.8394 0.538599 17.4726 0.538599 17.8631 0.929124Z"
                fill="#20a8d8" />
            </svg>
          </span>
        </div>
        <div className="data-card col-lg-3 col-md-4">
          <h3>{data?.performerLevelIncPercentage}</h3>
          <h4>Performer Level Percentage</h4>
          <div className={`view-edit ${editType=='performer'?'view-edit-active':''}`} >
            <div className="form-group">
              <input className="form-control" type="number" max="100" min="1" id="performerLevel" placeholder="Enter performer level" 
                  onBlur={((event)=>onSubmit(event.target.value,'performer'))} 
                  onKeyDown={(event)=>setPerformerLevel(event.target.value)}
                />
            </div>
          </div>
          <span className="link-text" onClick={()=>setEditType('performer')}>
            Click here to edit
            <svg width="25" height="16" viewBox="0 0 25 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd"
                d="M17.8631 0.929124L24.2271 7.29308C24.6176 7.68361 24.6176 8.31677 24.2271 8.7073L17.8631 15.0713C17.4726 15.4618 16.8394 15.4618 16.4489 15.0713C16.0584 14.6807 16.0584 14.0476 16.4489 13.657L21.1058 9.00019H0.47998V7.00019H21.1058L16.4489 2.34334C16.0584 1.95281 16.0584 1.31965 16.4489 0.929124C16.8394 0.538599 17.4726 0.538599 17.8631 0.929124Z"
                fill="#20a8d8" />
            </svg>
          </span>
        </div>
        <div className="data-card col-lg-3 col-md-4">
          <h3>{data?.avatarLevelIncPercentage}</h3>
          <h4>Avatar Level Percentage</h4>
          <div className={`view-edit ${editType=='avatar'?'view-edit-active':''}`}>
            <div className="form-group">
              <input className="form-control" type="number" max="100" min="1" id="avatarLevel" placeholder="Enter Avatar level"
                  onBlur={((event)=>onSubmit(event.target.value,'avatar'))}
                  onKeyDown={(event)=>setAvatarLevel(event.target.value)}
                />
            </div>
          </div>
          <span className="link-text" onClick={()=>setEditType('avatar')}>
            Click here to edit
            <svg width="25" height="16" viewBox="0 0 25 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd"
                d="M17.8631 0.929124L24.2271 7.29308C24.6176 7.68361 24.6176 8.31677 24.2271 8.7073L17.8631 15.0713C17.4726 15.4618 16.8394 15.4618 16.4489 15.0713C16.0584 14.6807 16.0584 14.0476 16.4489 13.657L21.1058 9.00019H0.47998V7.00019H21.1058L16.4489 2.34334C16.0584 1.95281 16.0584 1.31965 16.4489 0.929124C16.8394 0.538599 17.4726 0.538599 17.8631 0.929124Z"
                fill="#20a8d8" />
            </svg>
          </span>
        </div>
      </section>
    </DefaultLayout>
  )
}
