import React, { useState } from 'react'

export default function Pagination({total,pageSize,pageIndex,setPageIndex,setPageSize}) {
    const [totalIndex,setTotalIndex]= useState(3)
    function getIndexArray(){
        if(total<pageSize){
          return [0]
        }
        var totalIndex=parseInt(total/pageSize);
        if(pageIndex==0){
            return [0,1,2] 
        }
        if(totalIndex==pageIndex+1){
            return [pageIndex-1,pageIndex,pageIndex+1] 
        }
        if(pageIndex+1<totalIndex){
            return [pageIndex-1,pageIndex,pageIndex+1]
        }
        
        return [0,1,2]
    }

  return (
<div  className="custom-pag d-md-flex ng-star-inserted">
  <div>
    <span >
      <select className="custom-select w-auto mb-2 mb-md-0 ng-valid ng-dirty ng-touched" defaultValue={pageSize} onChange={(event)=>setPageSize(event.target.value)}>
        <option value="10" className="ng-star-inserted" >10</option>
        <option value="25" className="ng-star-inserted" >25</option>
        <option value="50" className="ng-star-inserted" >50</option>
        <option value="100" className="ng-star-inserted">100</option>
      </select>
    </span>
    <span  className="ml-1 fw-500">
      PageSize : {pageSize} (Total Records : {total})
    </span>
  </div>
  <div  className="ng-untouched ng-valid ng-dirty">
    <ul className="pagination">
        <li className="pagination-first page-item ng-star-inserted">
            <span className="page-link" onClick={()=>(pageIndex!==0?setPageIndex(0):'')}>«</span>
        </li>
        <li className="pagination-prev page-item ng-star-inserted">
            <span className="page-link" onClick={()=>(pageIndex!==0?setPageIndex(pageIndex-1):'')}>‹</span>
        </li>
        {
            getIndexArray().map((index,key)=>{
                return (<li key={key} className={`pagination-page page-item ${pageIndex==index?'active':''} ng-star-inserted`}>
                    <span onClick={()=>setPageIndex(index)} className={`page-link`}>{index+1}</span>
                </li>)
            })
        }
        <li className="pagination-next page-item ng-star-inserted">
            <span className="page-link" onClick={()=>(pageIndex+1!==parseInt(total/pageSize)?setPageIndex(pageIndex+1):'')}>›</span>
        </li>
        <li className="pagination-last page-item ng-star-inserted">
            <span className="page-link" onClick={()=>(pageIndex+1!==parseInt(total/pageSize)?setPageIndex(parseInt(total/pageSize)-1):'')}>»</span>
        </li>
    </ul>
</div>
</div>
  )
}
