import React, { useState } from 'react'
import DefaultLayout from '../../layouts/DefaultLayout'
import { useForm } from "react-hook-form";
import { useCreatBadgeMutation,useUpdateBadgeMutation,roleList } from '../../data/badges'
import {getBase64Encoded,showToast} from '../../util/helpers'
import { useNavigate } from "react-router-dom";
import { environment } from '../../config/environment';
import Spinner from '../../components/common/spinner';

const getViewFile=()=>{
    const params=new URLSearchParams(window.location.search)

    return params.get('file')?environment.baseurl+'/'+params.get('file'):''    
}

const getDefaultValues=()=>{
    const params=new URLSearchParams(window.location.search)
    if(params.get('id')){
        return {
            color:'#'+params.get('color'),
            group:params.get('group'),
            isCheck:params.get('isCheck').split(','),
            name:params.get('name'),
            id:params.get('id')
        }
    }
    return {color:'',group:'',isCheck:'',name:'',id:''}
}

export default function CreateEdit() {

    const { register, handleSubmit, watch, formState: { errors } } = useForm({defaultValues:getDefaultValues()});
    const { mutate: createMutate, isLoading: createloading } = useCreatBadgeMutation();
    const { mutate: updateMutate, isLoading: updateloading } = useUpdateBadgeMutation();
    const [file,setFile]= useState('')
    const [viewFile,setViewFile]= useState(getViewFile())

    const navigate = useNavigate();
    
    const isCreate=()=>{
        const params=new URLSearchParams(window.location.search)
        return !(params.get('id'))
    }
    function onSubmit({color,group,isCheck,name,id}){
        if(id){
            updateMutate({color,group,isCheck,name,file,id},
                {
                    onSuccess: (result) => {
                      if(result.data.message==='success'){
                          showToast('Badge updated successfully!','success')
                          setTimeout(() => {
                              navigate('/badges')
                          }, 1000);
                      }else{
                        showToast(result.data.message,'error')
                      }
                    },
                    onError: (error) => {
                      console.log(error.message);
                    }
                  }
            )
        }else{
            createMutate({color,group,isCheck,name,file},
                {
                    onSuccess: (result) => {
                      if(result.data.message==='success'){
                          showToast('Badge created successfully!','success')
                          navigate('/badges')
                      }else{
                        showToast(result.data.message,'error')
                      }
                    },
                    onError: (error) => {
                      console.log(error.message);
                    }
                  }
            )    
        }

    }
    function cancelClick(){
        navigate('/badges')
    }
    function changeBanner(event){
        if (event.target.files && event.target.files.length > 0) {
          getBase64Encoded(event.target.files[0]).then((data) => {
            setViewFile(data.fileType + data.base64);
          });
          setFile(event.target.files[0])
        }
    }

    function loadBanner(){
        document.querySelector('#banner').click();
    }
  return (
    <DefaultLayout>
        <Spinner loading={createloading||updateloading}/>

        <form onSubmit={handleSubmit(onSubmit)} >
            <div className="row">
                <div className="col-md-6 p-3">
                    <div>
                        <div className="header-title">
                            {isCreate() ? 'Create ' : 'Edit '} Badge
                        </div>
                        <input type='hidden' {...register('id')} />
                        <div className="row detail-grid">
                            <div className="col-4 detail-grid-left required"> Name <span> : </span> </div>
                            <div className="col-8">
                                <input className="form-control" type="text" placeholder="Enter name" {...register('name',{ required: true })} />
                            </div>
                        </div>
                        <div className="row detail-grid">
                            <div className="col-4 detail-grid-left required"> Role <span> : </span> </div>
                            <div className="col-8">
                                <div className="col-form-label d-flex flex-wrap p-0">
                                    {
                                        roleList.map((item,index)=>{
                                            return (
                                                <div className="form-check mr-2" key={index}>
                                                    <input className="form-check-input" type="checkbox" {...register('isCheck')} value={item.value} />
                                                    <label className="form-check-label">{item.name}</label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="row detail-grid">
                            <div className="col-4 detail-grid-left required"> Color <span> : </span> </div>
                            <div className="col-8">
                                <input type="color" {...register('color')} />
                            </div>
                        </div>
                        <div className="row detail-grid">
                            <div className="col-4 detail-grid-left"> Image Upload <span> : </span> </div>
                            <div className="col-8">
                                <input id="banner" type="file" accept='image/*' {...register('file')} style={{display: 'none'}} onChange={changeBanner} />
                                <button type='button' className="btn btn-secondary" onClick={()=>loadBanner()}> Image Upload </button>
                            </div>
                        </div>
                        <div className="row detail-grid">
                            <div className="col-4 detail-grid-left"> Image <span> : </span> </div>
                            <div className="col-8">
                                {
                                    viewFile&&(
                                        <img src={viewFile} style={{width:'50px'}}/>
                                    )
                                }
                            </div>
                        </div>
                        <div className="row detail-grid">
                            <div className="col-4 detail-grid-left"> Group <span> : </span> </div>
                            <div className="col-8">
                                <input className="form-control" type="text" placeholder="Enter group" {...register('group')} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row my-2">
                <div className="col-12">
                    <button className="btn btn-danger" type='submit'>
                        {isCreate() ? 'Save' : 'Update'}
                    </button>
                    <button className="btn btn-danger" type='button' style={{marginLeft:'3px'}} onClick={()=>cancelClick()}>
                        Cancel
                    </button>
                </div>
            </div>
        </form>

    </DefaultLayout>
  )
}
