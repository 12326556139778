import React, { useState } from 'react'
import DefaultLayout from '../../layouts/DefaultLayout'
import {useAnnouncementQuery,useDeleteAnnouncemntsMuatation,showAtName,showAtList} from '../../data/announcements'
import { getPagination} from "../../config/api";
import Pagination from '../../components/common/pagination'
import { showToast,checkPermission } from '../../util/helpers';
import { permissionList } from '../../data/permission-list';
import WorldBoardCastModel from '../../components/announcements/worldBoardCastModel';
import AddEditAdvertisement from '../../components/announcements/addEditAdvertisement';
import Spinner from '../../components/common/spinner';
import { apiConfigration } from '../../config/api';
import DeleteConfirm from '../../components/common/deleteConfirm';

export default function Users() {
    const [search,setSearch]= useState('')
    const [pageIndex,setPageIndex]= useState(0)
    const [limit,setLimit]= useState(25)
    const [skip,setSkip]= useState(0)
    const [showAt,setShowAt] =useState('')
    const [loading,setLoading] =useState('')

    const [modelShow,setModelShow]=useState(false)
    const [modelShow2,setModelShow2]=useState(false)
    const [editAnnouncement, setEditAnnouncement]=useState()

    const { data:dataList, isLoading: fetchloading ,refetch}  = useAnnouncementQuery({search,pageIndex,limit,skip,showAt:showAt})
    const {mutate:deleteMutate , isLoading: deleteloading } =useDeleteAnnouncemntsMuatation()

    function editAdvertisement(item){
        setEditAnnouncement(item)
        setModelShow2(true)
    }
    function getBadgesUser(){
        
    }
    function resetData(){
        setSearch('')
        setShowAt('all')
        setSearch('')
        document.querySelector('#search').value=''
        refetch()
    }
    function ViewMemberDetails(){

    }
    function addWorldAnnouncements(){
        setModelShow(true)
    }
    function addAdvertisement(){
        setEditAnnouncement('')
        setModelShow2(true)
    }
    function removeAdvertisement(item){
        
        deleteMutate(
            { id:item._id},
            {
            onSuccess: (result) => {
                if(result){
                    showToast('Announcement Successfully deleted!','success')
                    refetch()
                }
            },
            onError: (error) => {
                console.log(error.message);
            }
            })
    }
  return (
    <DefaultLayout>
        <Spinner loading={fetchloading||loading||deleteloading}/>

        <div className="row">
            <div className="col-md-4">
                <div className="input-group">
                    <div className="input-group-prepend" onClick={()=>getBadgesUser()}>
                        <span className="input-group-text"><i className="fa fa-search"></i></span>
                    </div>
                    <input type="text" className="form-control" placeholder="Search" id="search" style={{width:"200px"}} onChange={(event)=>setSearch(event.target.value)} />

                    <select className="form-control w-auto ml-md-2 mt-2 mt-md-0" onChange={(event)=>setShowAt(event.target.value)}>
                        <option value="''">All</option>
                        {
                            showAtList.map((item,i)=>{
                                return (
                                    <option key={i} value={item.value}>{item.name}</option>
                                )
                            })
                        }
                    </select>
    
                    <button type="button" className="btn btn-primary ml-2" onClick={()=>resetData()}>Clear</button>
                </div>
            </div>
            <div className="col-md-8 text-md-right mt-2 mt-md-0">
            {
                checkPermission(permissionList.AddWorldBroadcast)&&(
                    <button type="button" className="btn btn-primary mr-2" onClick={()=>addWorldAnnouncements()}>
                        <i className="fa fa-plus"></i> World Broadcast
                    </button>
                )
            }
            {
                checkPermission(permissionList.AddWorldBroadcast)&&(
                    <button type="button" className="btn btn-primary mr-2"
                    onClick={()=>addAdvertisement()}>
                        <i className="fa fa-plus"></i> Add
                    </button>
                )
            }
            </div>
        </div>
        <div className="row center-content">
            <div className="col-12">
                <table className="table table-striped table-responsive-md my-3">
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>Link / Text</th>
                        <th>Show At</th>
                        <th>Type</th>
                        <th>Impression</th>
                        <th>Clicks</th>
                        <th style={{width: "5%"}} className="text-center">Action</th>
                    </tr>
                </thead>
                <tbody >
                    {
                        dataList?.data?.map((item,i)=>{
                            return (
                                <tr key={i}>
                                    <td>{item?.title}</td>
                                    <td>{item?.link}</td>
                                    <td>{showAtName(item?.showAt)}</td>
                                    <td>{item?.type}</td>
                                    <td>{item?.impression}</td>
                                    <td>{item?.clicks}</td>
                                    <td>
                                        {
                                            checkPermission(permissionList.UpdateAnnouncements)&&(
                                            <i  className="fa fa-pencil cursor" tooltip="Edit"
                                                onClick={()=>editAdvertisement(item)}></i>
                                            )
                                        }
                                        {
                                            checkPermission(permissionList.DeleteAnnouncements)&&(
                                                <DeleteConfirm name="Announcement" url={`${apiConfigration.advertisement.remove}/${item?._id}`} refetch={()=>refetch()} setLoading={(value)=>setLoading(value)}/>
                                            )
                                        }
                                    </td>
                                </tr>
                            )
                        })
                    }
                    
                </tbody>
                </table>
            </div>
            <div className="col-12">
                <Pagination total={dataList?.count} pageIndex={pageIndex} pageSize={getPagination().pageSize} setPageIndex={setPageIndex} setPageSize={setLimit}/>
            </div>
        </div>

        <WorldBoardCastModel show={modelShow} setShow={()=>setModelShow()} setLoading={(value)=>setLoading(value)} refetch={()=>refetch()}/>
        <AddEditAdvertisement show={modelShow2} announcement={editAnnouncement} setLoading={(value)=>setLoading(value)} refetch={()=>refetch()} setShow={()=>setModelShow2()} />
    </DefaultLayout>
  )
}
