import React, { useState } from 'react'
import http from '../../util/http'
import { showToast } from '../../util/helpers'
import { useNavigate } from 'react-router-dom';

export default function DeleteConfirm({url,name,refetch,setLoading}) {
    const [show,setShow]= useState(false)
    const navigate = useNavigate()

    async function deleteItem(){
        console.log('deleting')
        setLoading(true)
        http.get(url)
        .then(()=>{
            setLoading(false)
            refetch()
            showToast(`${name} Deleted Successfully!`,'success')
            setShow(false)
            navigate('/members')
        }).catch(()=>setLoading(false))
    }  
  return (
    <div style={{position:'relative'}}>
        {/* onBlur={()=>setShow(false)} */}
            <button type="button" className="btn btn-danger mx-1 my-1"  onClick={()=>setShow(true)}>
                <i className="fa fa-user-times"></i> Delete Member
            </button>
            {
                show&&(
                <div  className="ng-star-inserted">
                    <div  className="popover left popover-left bs-popover-left ng-star-inserted confirm-position">
                        <div  className="popover-arrow arrow">
                        </div>
                        <h3  className="popover-title popover-header">Are you sure ?</h3>
                        <div  className="popover-content popover-body">
                            <p >You want to remove the record ?</p>
                            <div  className="confirm-btns">
                                <div  className="confirm-btn-container ng-star-inserted">
                                    <button  type="button" className="btn btn-block btn-outline-secondary" onClick={()=>setShow(false)}>Cancel</button>
                                </div>
                                <div  className="confirm-btn-container ng-star-inserted">
                                    <button  type="button" className="btn btn-block btn-danger" onClick={()=>deleteItem()}>Confirm</button>
                                </div>    
                            </div>
                        </div>
                    </div>
                </div>
                )
            }
            
  </div>
  )
}
