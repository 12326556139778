import http from "../util/http";
import { getUser } from "../util/helpers";
import { useMutation,useQuery } from "react-query";
import { apiConfigration ,getPagination} from "../config/api";

async function getAllUsers({queryKey}){
    const {limit,pageIndex,search} = queryKey[1]
    const {pageSize} = getPagination() 

    const { data } =await http.get(apiConfigration.user.all+`?search=${search}&pageIndex=${(pageIndex)}&limit=${limit}&skip=${(pageIndex) * pageSize}`)

    return data;
}


export function useFetchAllUsersQuery(options){
    return useQuery(
        ['getAllUsers', options],
        getAllUsers
      )
}

async function addEditUser(input){
    const {id}=input
    if(id){
        return http.post(`${apiConfigration.user.api}/${id}`, input)
    }
    return http.post(`${apiConfigration.user.api}`, input)
}
export function useAddEditUserMutation(){
    return useMutation('addEditUser',addEditUser)
}

export const genderList = ['Male', 'Female'];


async function resetPassword(input){
    return http.post(apiConfigration.login.changePsw, input)
}
export function useResetPasswordMutation(){
    return useMutation('resetPassword',resetPassword)
}

async function updatePreference(input){
    const data=getUser()
    data.preferences=input
    const {id}=input;
    window.localStorage.setItem('user',JSON.stringify(data))
    return http.post(`${apiConfigration.admin.post}/${id}`, data)
}
export function usePreferenceMutate(){
    return useMutation('updatePreference',updatePreference)
}

async function useSuspend(input){
    const {_id}=input;
    return http.post(`${apiConfigration.user.suspendUser}/${_id}`, input)
}
export function useSuspendMutation(){
    return useMutation('useSuspend',useSuspend)
}

async function addNote(input){
    const {_id,file,note}=input;

    const formData = new FormData();
    formData.append('note', note);
    formData.append('file', file);
    formData.append('uid', _id);

    return http.post(`${apiConfigration.user.addUserNote}/${_id}`, input)
}
export function useAddNoteMutation(){
    return useMutation('addNote',addNote)
}
