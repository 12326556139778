export const permissionList = {
    ViewChangePassword: 'VCHPS',
    ViewChangePreferences: 'VCHPF',
  
    ViewManageAdmins: 'VMAAD',
    AddAdmin: 'AADMI',
    UpdateAdmin: 'UADMI',
    DeleteAdmin: 'DADMI',
  
    ViewMember: 'VMEMB',
    AddMember: 'AMEMB',
    UpdateMember: 'UMEMB',
    DeleteMember: 'DMEMB',
    ViewMemberDetail: 'VMEMD',
    SuspendMember: 'SMEMB',
  
    AddWorldBroadcast: 'AWBRO',
    ViewAnnouncements: 'VANNO',
    AddAnnouncements: 'AANNO',
    UpdateAnnouncements: 'UANNO',
    DeleteAnnouncements: 'DANNO',
  
    ViewEmailTemplate: 'VEMTE',
    AddEmailTemplate: 'AEMTE',
    DeleteEmailTemplate: 'DEMTE',
  
    ViewRegions: 'VREGI',
    UpdateRegions: 'UREGI',
    ViewDimension: 'VDIME',
    AddDimension: 'ADIME',
    UpdateDimension: 'UDIME',
    DeleteDimension: 'DDIME',
  
    ViewAdminActivity: 'VADAC',
    ViewGameConfig: 'VGMCO',
    UpdateGameConfig: 'UGMCO',
    ViewAbuseReport: 'VABRE',
  
    ViewBadges: 'VBADG',
    AddBadges: 'ABADG',
    UpdateBadges: 'UBADG',
    DeleteBadges: 'DBADG',
  
    AddUserBadges: 'AUBAD',
    DeleteUserBadges: 'DUBAD',
  
    ViewBroadcastLog: 'VBCLO',
  };
  