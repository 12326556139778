import React from 'react'
import { timeTransform } from '../../util/helpers';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
  } from "chart.js";
import { Line } from "react-chartjs-2";
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);


export default function LineChart({data,selectedGraph}) {
  function customTooltip(value){
    console.log(value)
    return 1
    // let sum = 0;

    // tooltipItems.forEach(function(tooltipItem) {
    //   sum += tooltipItem.parsed.y;
    // });
    // console.log(sum)
    // return 'Sum: ' + sum;
  }
    const options= {
        plugins: {
          legend: {
            position: "bottom",
            display:false
          },
          title: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: (tooltipItem, data) => {
                if (selectedGraph == 1) {
                  if (tooltipItem.datasetIndex == 0) {
                    return 'Number Of Members : ' + tooltipItem.parsed.y;
                  }
                  if (tooltipItem.datasetIndex == 1) {
                    return 'Total Time Spent : ' + timeTransform(tooltipItem.parsed.y);
                  }
                  if (tooltipItem.datasetIndex == 2) {
                    return 'Average Time Spent : ' + timeTransform(tooltipItem.parsed.y);
                  }
                }
                if (selectedGraph == 2) {
                  if (tooltipItem.datasetIndex == 0) {
                    return 'Number Of Members : ' + tooltipItem.parsed.y;
                  }
                  if (tooltipItem.datasetIndex == 1) {
                    return 'Total Time Spent : ' + timeTransform(tooltipItem.parsed.y);
                  }
                  if (tooltipItem.datasetIndex == 2) {
                    return 'Average Time Spent : ' + timeTransform(tooltipItem.parsed.y);
                  }
                }
                if (selectedGraph == 3) {
                  return 'Total Number Of New Members : ' + tooltipItem.parsed.y;
                }
                if (selectedGraph == 4) {
                  return 'Monthly Active Member Count : ' + tooltipItem.parsed.y;
                }
                if (selectedGraph == 5) {
                  return 'Number Of Booty Spent : ' + tooltipItem.parsed.y;
                }
              },
            }    
          },
        },
        hover: {
          mode: 'dataset',
        },
        responsive: true,
        maintainAspectRatio: false,
      };
      

    function getDataGraph() {
        var selectedGraphData = {
            datasets: [
              {
                label: "Number of Members",
                data: [],
                fill: true,
                borderColor: "rgb(230,91,131)",
                backgroundColor: "rgb(230,91,131,0.4)",
                pointBackgroundColor: "rgb(230,91,131,0.7)",
                pointBorderColor: "transparent",
                cubicInterpolationMode: 'monotone',
                tension: 1,
              },
              {
                label: "Average Time Spent",
                data: [],
                fill: true,
                borderColor: "rgb(248,205,86)",
                backgroundColor: "rgb(248,205,86,0.4)",
                pointBackgroundColor: "rgb(248,205,86,0.7)",
                pointBorderColor: "transparent",
                cubicInterpolationMode: 'monotone',
                tension: 1,
              },
              {
                label: "Total Time Spent",
                data: [],
                fill: true,
                borderColor: "rgb(66,162,235)",
                backgroundColor: "rgb(66,162,235,0.4)",
                pointBackgroundColor: "rgb(66,162,235,0.7)",
                pointBorderColor: "transparent",
                cubicInterpolationMode: 'monotone',
                tension: 1,
              },
            ],
            labels: [],
        };
        selectedGraphData.datasets.data = [];
        selectedGraphData.labels = [];
        
        if (selectedGraph == 1) {
          if (data?.dailyActiveUserReport && data?.dailyActiveUserReport.data) {
            selectedGraphData.datasets[0].data= data?.dailyActiveUserReport.noOfUsers
            selectedGraphData.datasets[1].data= data?.dailyActiveUserReport.avg 
            selectedGraphData.datasets[2].data= data?.dailyActiveUserReport.data
            selectedGraphData.labels = data?.dailyActiveUserReport.labels;
          }
        }
    
        if (selectedGraph == 2) {
          if (data?.monthlyActiveUserReport && data?.monthlyActiveUserReport.data) {
            selectedGraphData.datasets[0].data= data?.monthlyActiveUserReport.noOfUsers
            selectedGraphData.datasets[1].data= data?.monthlyActiveUserReport.avg 
            selectedGraphData.datasets[2].data= data?.monthlyActiveUserReport.data
          
            selectedGraphData.labels = data?.monthlyActiveUserReport.labels;
          }
        }
    
        if (selectedGraph == 3) {
          if (data?.UsersLast30Days && data?.UsersLast30Days.data) {
            selectedGraphData.datasets[0].data= data?.UsersLast30Days.data
            selectedGraphData.datasets[0].data.label="Total Number Of New Members"
            selectedGraphData.labels = data?.UsersLast30Days.labels;
          }
        }
    
        if (selectedGraph == 4) {
          if (data?.monthlyUsesReport && data?.monthlyUsesReport.data) {
            selectedGraphData.datasets[0].data= data?.monthlyUsesReport.data
            selectedGraphData.datasets[0].data.label="Total Number Of New Members"
            selectedGraphData.labels = data?.monthlyUsesReport.labels;
          }
        }
    
        if (selectedGraph == 5) {
          if (data?.totalBootySpent && data?.totalBootySpent.data) {
            selectedGraphData.datasets[0].data= data?.totalBootySpent.data
            selectedGraphData.datasets[0].data.label="Total Booty"
            selectedGraphData.labels = data?.totalBootySpent.labels;
          }
        }

        return selectedGraphData;
      }
  return (
    <>
        <Line options={options} data={getDataGraph(data)}/>
    </>
  )
}
