import React from 'react'
import {BrowserRouter,Route,Routes,Navigate} from "react-router-dom";
import Login from '../pages/auth/login';
import Dashboard from '../pages/dashboard/index';
import Badges from '../pages/badges/index';
import BadgesMembers from '../pages/badges/members';
import BadgeCreateEdit from '../pages/badges/create-edit'
import Announcement from '../pages/announcements/index';
import EmailTemplate from '../pages/emailTemplate/index'
import Regions from '../pages/regions/index'
import AdminActivity from '../pages/adminActivities/index';
import GameConfig from '../pages/gameConfig/index'
import Report from '../pages/report/index'
import BroadCastLog from '../pages/broadCastLog/index'
import Users from '../pages/users/index';
import UsersOverview from '../pages/users/detail/overview';
import UsersBank from '../pages/users/detail/bank.js';
import UsersIpHistory from '../pages/users/detail/ip-history.js';
import UsersID from '../pages/users/detail/user-id.js';
import UsersRelationTable from '../pages/users/detail/relation-table.js';
import UsersAchievements from '../pages/users/detail/achievements.js';
import UsersStatistics from '../pages/users/detail/statistics.js';
import UsersProperty from '../pages/users/detail/property.js';
import UsersTraces from '../pages/users/detail/traces.js';
import UsersAvatar from '../pages/users/detail/avatar.js';
import UsersAdminLog from '../pages/users/detail/admin-log.js';
import AdminList from '../pages/adminList/index';



import { isAuthenticated } from './helpers';
import ProtectedRoutes from './protectedRoutes'

function AppRouter(){

    const requireLogin = (to, from, next) => {
        if (to.meta.auth) {
          if (isAuthenticated()) {
            next();
          }
          next.redirect('/login');
        } else {
          next();
        }
      };
    return(
        <>
            <BrowserRouter>
                <Routes>
                  <Route element={<ProtectedRoutes/>}>
                    <Route path="/dashboard" name="Dashboard" element={<Dashboard/>} />
                    <Route path="/members" name="Members" element={<Users/>} />

                    <Route path="/members/detail/overview" name="UsersOverview" element={<UsersOverview/>} />
                    <Route path="/members/detail/bank" name="UsersBank" element={<UsersBank/>} />
                    <Route path="/members/detail/ip-history" name="UsersIpHistory" element={<UsersIpHistory/>} />
                    <Route path="/members/detail/user-id" name="UsersID" element={<UsersID/>} />
                    <Route path="/members/detail/relation-table" name="UsersRelationTable" element={<UsersRelationTable/>} />
                    <Route path="/members/detail/achievements" name="UsersAchievements" element={<UsersAchievements/>} />
                    <Route path="/members/detail/statistics" name="UsersStatistics" element={<UsersStatistics/>} />
                    <Route path="/members/detail/property" name="UsersProperty" element={<UsersProperty/>} />
                    <Route path="/members/detail/traces" name="UsersTraces" element={<UsersTraces/>} />
                    <Route path="/members/detail/admin-log" name="UsersAdminLog" element={<UsersAdminLog/>} />
                    <Route path="/members/detail/avatar" name="UsersAvatar" element={<UsersAvatar/>} />
                    
                    <Route path="/badges" name="Badges" element={<Badges/>} />
                    <Route path="/badges/create-edit" name="Badges" element={<BadgeCreateEdit/>} />
                    <Route path="/badges/members" name="Badges" element={<BadgesMembers/>} />
                    <Route path="/announcement" name="Announcement" element={<Announcement/>} />
                    <Route path="/email-template" name="EmailTemplate" element={<EmailTemplate/>} />
                    <Route path="/regions" name="Regions" element={<Regions/>} />
                    <Route path="/admin-activity" name="AdminActivity" element={<AdminActivity/>} />
                    <Route path="/game-config" name="GameConfig" element={<GameConfig/>} />
                    <Route path="/report" name="Report" element={<Report/>} />
                    <Route path="/broadcast-log" name="BroadCastLog" element={<BroadCastLog/>} />
                    <Route path="/admin-list" name="AdminList" element={<AdminList/>} />
                  </Route>
                  <Route exact path='/login' element={<Login/>}/>
                  <Route path="*" element={<Navigate to={isAuthenticated()?'/dashboard':'/login'}/>} />
                </Routes>
            </BrowserRouter>
        </>
    )
}
  
  
export default (AppRouter);