import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { timeTransform } from "../../util/helpers";
import { Line } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

export const options = {
  maintainAspectRatio: false,
  // responsive: true,
  // aspectRatio: 2,
  plugins: {
    legend: {
      position: "bottom",
      display:false
    },
    title: {
      display: false,
    },
    interaction: {
      intersect: false,
      mode: 'index',
    },
    tooltip: {
      mode: 'index',
      position: 'nearest',
      callbacks: {
        label: (tooltipItem, data) => {
          return 'Total Time : ' + timeTransform(tooltipItem.parsed.y);
        }
      }
    },
  },
  scales: {
    xAxes: [
      {
        stacked: true,
        ticks: {
            beginAtZero: true,
            max: 1000,
            min: 0
        }
      },
    ],
    yAxes: [
      {
        stacked: true,
        ticks: {
            beginAtZero: false,
            max: 8,
            min: -3
        }
      },
    ],
  },
  zoom: {
    enabled: true,
    mode: "x",
  },
  pan: {
    enabled: true,
    mode: "x",
  },
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];

export default function BankLineChart({ data }) {
  return (
    <>
      {data&&<Line options={options} data={data} />}
    </>
  );
}
