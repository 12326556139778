import React, { useState ,useEffect} from 'react'
import DefaultLayout from '../../layouts/DefaultLayout'
import {useFetchBroadCastLogQuery} from '../../data/broadcast-log'
import { getPagination} from "../../config/api";
import Pagination from '../../components/common/pagination'
// import {environment} from '../config/environment'
import Spinner from '../../components/common/spinner';
import moment from 'moment';

export default function BroadCastLog() {
    const [search,setSearch]= useState('')
    const [pageIndex,setPageIndex]= useState(0)
    const [pageSize,setPageSize]= useState(25)
    const [limit,setLimit]= useState(25)
    const [skip,setSkip]= useState(0)
    const { data:dataList, isLoading: fetchloading ,refetch}  = useFetchBroadCastLogQuery({search,pageIndex,limit:pageSize,skip})
    useEffect(() => {
      
      refetch()
    }, [search,pageIndex,pageSize,limit,skip,refetch]);

    function resetData(){
      setSearch('')
      document.querySelector('.search').value=''
      refetch()
    }
    function ViewMemberDetails(){

    }
  return (
    <DefaultLayout>
        <Spinner loading={fetchloading}/>
        <div className="row">
            <div className="col-md-3">
                <div className="input-group">
                    <div className="input-group-prepend" onClick={()=>refetch()}>
                        <span className="input-group-text"><i className="fa fa-search"></i></span>
                    </div>
                    <input type="text" className="form-control search" placeholder="Search" id="search" onKeyUp={(event)=>setSearch(event.target.value)} />

                    <button type="button" className="btn btn-primary ml-2" onClick={()=>resetData}>Clear</button>
                </div>
            </div>
        </div>
        <div className="row center-content">
            <div className="col-12">
                <table className="table table-striped table-responsive-md my-3">
                <thead>
                    <tr>
                      <th>Date / Time</th>
                      <th>BroadcastID</th>
                      <th>From</th>
                      <th>Member Name</th>
                      <th>Message</th>
                  </tr>
                </thead>
                <tbody >
                    {
                        dataList?.data?.map((item,i)=>{
                            return (
                              <tr key={i}>
                              <td>{moment(item?.cd).format('DD-MMM-yyyy hh:mm:ss a')}</td>
                              <td>{item?._id}</td>
                              <td>
                                <a className="custom-a" href="" tooltip="View Member Details"
                                  onClick={()=>ViewMemberDetails(item)}>
                                  {item?.from}
                                </a>
                              </td>
                              <td>{item?.un}</td>
                              <td>{item?.msg}</td>
                            </tr>
                            )
                        })
                    }
                    
                </tbody>
                </table>
            </div>
            <div className="col-12">
                <Pagination total={dataList?.count} pageIndex={pageIndex} pageSize={pageSize} setPageIndex={setPageIndex} setPageSize={setPageSize}/>
            </div>
        </div>
    </DefaultLayout>
  )
}
