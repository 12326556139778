import React, { useState,useEffect } from 'react'
import { useForm } from "react-hook-form";
import { useAddEmailRegionMutation } from '../../data/region';
import { showToast } from '../../util/helpers';
import Spinner from '../common/spinner';


export default function AddEditModel({region,show,setShow,setLoading,refetch}) {

    const { register, handleSubmit, watch,reset, formState: { errors, } } = useForm();
    const { mutate: addEditRegionMutate, isLoading: fetchloading } = useAddEmailRegionMutation();

    useEffect(()=>{
        if(region?.room){
            reset(getDefaultValues())
        }
    },[region?.room])

    function getDefaultValues(){
        return {
            id:region?._id,
            room:region?.room,
            cover_charge:region?.cover_charge,
            streaming_link:region?.streaming_link
        }  
    }
    
    function closeModel(){
        setShow(false)
    }

    function onSubmit(inputs)
    {
        setLoading(true)
        
            addEditRegionMutate(
                {
                    ...inputs,
                },
                {
                    onSuccess: (result) => {
                        setLoading(false)
                        if(result.data.message=='success'){
                            showToast('Regions Successfully stored','success')
                            setTimeout(() => {
                                reset('')
                                setShow(false)
                            }, 800);
                        }else{
                            showToast(result.data.message,'error')
                        }
                        refetch()
                    }
                })
        
    }

  return (
    <>
        {
            show&&(
                <div className={`modal-backdrop fade in ${show?'show':''}`}></div>
            )
        }
        <Spinner loading={fetchloading}/>
        <div role="dialog" tabIndex="-1" className={`modal fade ${show?'show':''}`} style={{display: (show?'block':'none')}} aria-modal="true">
            <div role="document" className="modal-dialog">
                <form onSubmit={handleSubmit(onSubmit)} className="modal-content">
                    <div className="card m-0">
                        <div className="card-header modelHeader">
                            <div className="row">
                            <div className="col-10 align-self-center">
                                <h5>{!region ? 'Add' : 'Edit'} Region</h5>
                            </div>
                            <div className="col-2 text-right">
                                <i className="fa fa-times cursor" onClick={()=>closeModel()}></i>
                            </div>
                            </div>
                        </div>

                        <div className="card-body">
                            <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                <label className="col-form-label font-weight-bold required">Room</label>
                                <input className="form-control" type="text" placeholder="Enter room name"  {...register('room',{required:true})}/>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                <label className="col-form-label font-weight-bold">Cover Charge</label>
                                <input className="form-control" type="number" placeholder="Enter cover charge" {...register('cover_charge',{required:true})}/>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                <label className="col-form-label font-weight-bold">Streaming Link</label>
                                <input className="form-control" type="text" placeholder="Enter streaming link" {...register('streaming_link',{required:true})}/>
                                </div>
                            </div>
                            </div>
                        </div>

                        <div className="card-footer modelFooter">
                            <div className="float-right">
                                <button className="btn btn-primary mr-2">
                                    {!region ? 'Save' : 'Update'}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </>
  )
}
