import React, {useEffect,useState} from 'react'
import UserDetailLayout from '../../../layouts/UserDetailLayout'
import { useUserOverviewQuery } from '../../../data/user-overview'
import Spinner from '../../../components/common/spinner'
import { useSearchParams } from 'react-router-dom'
import BankLineChart from '../../../components/users/bankLineChart'

export default function Bank() {
  const [chartData, setChartData] = useState('');
  const [searchParams,setSearchParams]=useSearchParams()

  const { data:userDetails, isLoading: fetchloading ,refetch}  = useUserOverviewQuery({id:searchParams.get('id')})

  function getGraphData(data){
    console.log(data?.usersActivityReport?.data)
    if(data){
      var x_labels=data?.usersActivityReport?.labels
      var y_datasets=[]
      var activityData = {
        label: "Value",
        data: data?.usersActivityReport?.data,
        fill: true,
        borderColor: "rgb(230,91,131)",
        backgroundColor: "rgb(230,91,131,0.4)",
        pointBackgroundColor: "rgb(230,91,131,0.7)",
        pointBorderColor: "transparent",
        cubicInterpolationMode: 'monotone',
        tension: 1,
      };
      y_datasets.push(activityData)
      console.log(y_datasets)
      
      return {
        labels: x_labels,
        datasets: y_datasets,
      }
    }
  }
  return (
    <UserDetailLayout>
      <Spinner loading={fetchloading}/>

      <div className="row">
        <div className="col-md-2 p-3">
          <div className="row detail-grid">
            <div className="col-6 detail-grid-left font-weight-bold"> Booty Bank <span> : </span> </div>
            <div className="col-6">
              {userDetails?.booties?.bank}
            </div>
          </div>
          <div className="row detail-grid">
            <div className="col-6 detail-grid-left font-weight-bold"> Booty Bonus <span> : </span> </div>
            <div className="col-6">
              {userDetails?.booties?.bonus}
            </div>
          </div>
        </div>
        <div className="col-md-2 p-3">
          <div className="row detail-grid">
            <div className="col-6 detail-grid-left font-weight-bold"> Booty Main <span> : </span> </div>
            <div className="col-6">
              {userDetails?.booties?.main}
            </div>
          </div>
          <div className="row detail-grid">
            <div className="col-6 detail-grid-left font-weight-bold"> Total Booty <span> : </span> </div>
            <div className="col-6">
              {userDetails?.booty}
            </div>
          </div>
        </div>
        <div className="col-md-2 p-3">
          <div className="row detail-grid">
            <div className="col-6 detail-grid-left font-weight-bold"> Booty Points <span> : </span> </div>
            <div className="col-6">
              {userDetails?.bootyPoints}
            </div>
          </div>
          <div className="row detail-grid">
            <div className="col-6 detail-grid-left font-weight-bold"> Booty Tipped <span> : </span> </div>
            <div className="col-6">
              {userDetails?.bootyTipped}
            </div>
          </div>
        </div>
      </div>
      <div  style={{height: "calc(100vh - 360px)"}}>
        <BankLineChart data={getGraphData(userDetails)}/>
      </div>

    </UserDetailLayout>
  )
}
