import http from "../util/http";
import { useMutation,useQuery } from "react-query";
import { apiConfigration } from "../config/api";


async function getGameConfig(){
    const { data } = await http.get(apiConfigration.gameConfig.api)

    return data.data;
}
export function useGameConfigQuery(){
    return useQuery("getGameConfig",getGameConfig)
}

async function updateGameConfig(input){
    return http.post(apiConfigration.gameConfig.api,input)
}
export function useUpdateGameConfigMutation(){
    return useMutation('updateGameConfig',updateGameConfig)
}