import React, { useState } from 'react'
import UserDetailLayout from '../../../layouts/UserDetailLayout'
import {useUserFriendsDetailQuery,useUserIgnoredFriendsDetailQuery,tableList} from '../../../data/relation-table'
import { getPagination} from "../../../config/api";
import Pagination from '../../../components/common/pagination'
import Spinner from '../../../components/common/spinner'
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom'


export default function RelationTable() {

  const navigate = useNavigate();
  const [search,setSearch]= useState('')
  const [pageIndex,setPageIndex]= useState(0)
  const [limit,setLimit]= useState(25)
  const [skip,setSkip]= useState(0)
  const [searchParams,setSearchParams]=useSearchParams()
  const [activeTable, setActiveTable] = useState('friends')
  const { data:friendList, isLoading: fetchloading ,refetch}  = useUserFriendsDetailQuery({search,pageIndex,limit,skip,id:searchParams.get('id')})
  const { data:ignoredFriendList, isLoading: fetchloading2 ,refetch2}  = useUserIgnoredFriendsDetailQuery({search,pageIndex,limit,skip,id:searchParams.get('id')})

  return (
    <UserDetailLayout>
      <Spinner loading={fetchloading||fetchloading2}/>
      <div className="row">
        <div className="col-md-8">
          {
            tableList?.map((item,j)=>{
              return (
                <div key={j}>
                  {
                    (activeTable == 'friends' && item?.url == 'friends')&&(
                      <div>
                        <div className="header-title">
                          <i className="fa fa-caret-left cursor float-left" onClick={()=>setActiveTable('traces')}></i>
                          Friends
                          <i className="fa fa-caret-right cursor float-right" onClick={()=>setActiveTable('ignores')}></i>
                        </div>
                
                        <div className="col-12">
                          <table className="table table-striped table-responsive-md my-3">
                            <thead>
                              <tr>
                                <th>Member ID</th>
                                <th>Member Name</th>
                                <th>Favourite</th>
                                <th>Rank</th>
                                <th>Created</th>
                                <th>Deleted</th>
                                <th>Online</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                friendList?.data?.map((item,i)=>{
                                  return (
                                  <tr key={i}>
                                    <td>{item?._id}</td>
                                    <td>{item?.un}</td>
                                    <td>{item?.favourite ? 'Yes' : 'No'}</td>
                                    <td>{i+1}</td>
                                    <td>{item?.cd}</td>
                                    <td>
                                      {item?.deleted ? 'Yes' : 'No'}
                                      {item?.deletedOn}
                                    </td>
                                    <td>
                                      {
                                        (item?.isOnline==1)&&(
                                          <span className="badge bg-success">Online</span>
                                        )
                                        (item?.isOnline==0)&&(
                                          <span className="badge bg-danger">Offline</span>
                                        )
                                      }
                                    </td>
                                  </tr>
                                  )
                                })
                              }
                            </tbody>
                          </table>
                        </div>
                        <div className="col-12">
                          <Pagination total={friendList?.count} pageIndex={pageIndex} pageSize={getPagination().pageSize} setPageIndex={setPageIndex} setPageSize={setLimit}/>
                        </div>
                      </div>
                    )
                  }
                  {
                    (activeTable == 'ignores' && item?.url == 'ignores')&&(
                      <div>
                        <div className="header-title">
                          <i className="fa fa-caret-left cursor float-left" onClick={()=>setActiveTable('friends')}></i>
                          Ignores
                          <i className="fa fa-caret-right cursor float-right" onClick={()=>setActiveTable('traces')}></i>
                        </div>
                
                        <div className="col-12">
                          <table className="table table-striped table-responsive-md my-3">
                            <thead>
                              <tr>
                                <th>Member ID</th>
                                <th>Member Name</th>
                                <th>Favourite</th>
                                <th>Rank</th>
                                <th>Deleted</th>
                                <th>Online</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                ignoredFriendList?.data?.map((item,i)=>{
                                  return (
                                    <tr key={i}>
                                      <td>{item?._id}</td>
                                      <td>{item?.un}</td>
                                      <td>{item?.favourite ? 'Yes' : 'No'}</td>
                                      <td>{i+1}</td>
                                      <td>{item?.deleted ? 'Yes' : 'No'}</td>
                                      <td>
                                      {
                                        (item?.isOnline==1)&&(
                                          <span className="badge bg-success">Online</span>
                                        )
                                        (item?.isOnline==0)&&(
                                          <span className="badge bg-danger">Offline</span>
                                        )
                                      }
                                      </td>
                                    </tr>
                                  )
                                })
                              }
                              
                            </tbody>
                          </table>
                        </div>
                        <div className="col-12">
                          <Pagination total={ignoredFriendList?.count} pageIndex={pageIndex} pageSize={getPagination().pageSize} setPageIndex={setPageIndex} setPageSize={setLimit}/>
                        </div>
                      </div>
                    )
                  }
                  {
                    (activeTable == 'traces' && item.url == 'traces')&&(
                      <div>
                        <div className="header-title">
                          <i className="fa fa-caret-left cursor float-left" onClick={()=>setActiveTable('ignores')}></i>
                          Traces
                          <i className="fa fa-caret-right cursor float-right" onClick={()=>setActiveTable('friends')}></i>
                        </div>
                      </div>
                    )
                  }
                </div>
              )
            })
          }
          
        </div>
      </div>
    </UserDetailLayout>
  )
}
