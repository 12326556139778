import React,{ useState } from "react";
import DefaultLayout from "./DefaultLayout"
import { useNavigate,useSearchParams } from 'react-router-dom';


const tablist = [
    { name: 'Overview', url: 'overview' },
    { name: 'Bank', url: 'bank' },
    { name: 'IP History', url: 'ip-history' },
    { name: 'ID', url: 'user-id' },
    { name: 'Relations', url: 'relation-table' },
    { name: 'Achievements', url: 'achievements' },
    { name: 'Statistics', url: 'statistics' },
    { name: 'Avatar', url: 'avatar' },
    { name: 'Property', url: 'property' },
    { name: 'Traces', url: 'traces' },
    { name: 'Admin Log', url: 'admin-log' },
  ];
const UserDetailLayout = ({ children }) => {
    const navigate = useNavigate()
    const [selectedTab,setSelectedTab] = useState('overview')
    const [searchParams,setSearchParams] = useSearchParams()
    function goBack(){
        navigate(`/members`)
    }
    function selectTab(url){
        setSelectedTab(url)
        navigate(`/members/detail/${url}?id=${searchParams.get('id')}`)
    }
    function isSelected(url){
        return window.location.href.includes(url)
    }
    return (
        <DefaultLayout>
            <div className="card my-4">
                <div className="card-header modelHeader">
                    <div className="row">
                        <div className="col-12 align-self-center">
                            <i className="fa fa-arrow-left cursor" onClick={()=>goBack()}></i>
                            <h5 className="d-inline ml-1">Member Details</h5>
                        </div>
                    </div>
                </div>

                <div className="card-body">
                    <div className="tab">
                    {
                        tablist.map((item,key)=>{
                            return (
                                <div key={key}>
                                    <button className={`${isSelected(item.url) ? 'active' : ''}`} onClick={()=>selectTab(item.url)}>{item.name}</button>
                                </div>
                            )
                        })
                    }
                    </div>

                    <div className="tabcontent">
                        {children}
                    </div>
                </div>
            </div>
            {/* <div>
                <tr>
                    <td colspan="100" className="text-center font-2xl">No data available</td>
                </tr>
            </div> */}
        </DefaultLayout>
    )
}
export default UserDetailLayout;