import React, { useState } from 'react'
import DefaultLayout from '../../layouts/DefaultLayout'
import {useFetchAllAdminsQuery} from '../../data/adminList'
import { useSearchParams } from 'react-router-dom'
import { getPagination} from "../../config/api";
import Pagination from '../../components/common/pagination'
import Spinner from '../../components/common/spinner';
import { useNavigate } from 'react-router-dom';
import AddEditModel from '../../components/adminList/addEditModel';
import { checkPermission } from '../../util/helpers';
import { apiConfigration } from '../../config/api';
import { getUser } from '../../util/helpers';
import { permissionList } from '../../data/permission-list';
import DeleteConfirm from '../../components/common/deleteConfirm';
import Preferences from '../../components/adminList/preferenceModel';

export default function AdminList() {
    const navigate = useNavigate();
    const [search,setSearch]= useState('')
    const [pageIndex,setPageIndex]= useState(0)
    const [limit,setLimit]= useState(25)
    const [skip,setSkip]= useState(0)
    const [selectedAdmin,setSelectedAdmin] =useState('')
    const [selectedPageSize,setSelectedPageSize] =useState('')

    
    const [modelShow,setModelShow]=useState(false)
    const [modelShow2,setModelShow2]=useState(false)
    const [loading,setLoading] =useState('')

    const [searchParams,setSearchParams]=useSearchParams()
    const { data:dataList, isLoading: fetchloading ,refetch}  = useFetchAllAdminsQuery({search:search,pageIndex,limit,skip,id:searchParams.get('id')})

    function setDefaultImage(event){
        event.target.src="/images/no-profile-image.jpg"
    }
    function resetData(){
        document.querySelector('#search').value=''
        setSearch('')
        refetch()
    }
    function addAdmin(){
        setModelShow(true)
    }
    function editAdmin(item){
        console.log(selectedAdmin)
        setSelectedAdmin(item)
        setModelShow(true)
    }
    
    function ViewMemberDetails(item){
        navigate('/members/detail/overview?id='+item._id)
    }

    function selectPreferences(item){
        setSelectedAdmin(item)
        setModelShow2(true)
    }

    function checkSecurity(item) {
        if (item.access && item.access.length > 0) {
          const isAccess = item.access.find(m => m == permissionList.ViewManageAdmins);
          if (isAccess) {
            if (item?._id == getUser()?._id) {
              return '/images/check_gray.svg';
            } else {
              return '/images/check_green.svg';
            }
          } else {
            return '/images/uncheck.svg';
          }
        } else {
          return '/images/uncheck.svg';
        }
      }
  return (
    <DefaultLayout>
        <Spinner loading={fetchloading||loading}/>

        <div className="row">
            <div className="col-md-3">
                <div className="input-group">
                    <div className="input-group-prepend" onClick={()=>refetch()}>
                        <span className="input-group-text"><i className="fa fa-search"></i></span>
                    </div>
                    <input type="text" className="form-control" placeholder="Search" id="search" onChange={(event)=>setSearch(event.target.value)} />
                            {/* (keyup.enter)="" */}
                        {/* [(ngModel)]="pagination.search" */}
                    <button type="button" className="btn btn-primary ml-2" onClick={()=>resetData()}>Clear</button>
                </div>
            </div>
            {
                checkPermission(permissionList.AddMember)&&(

                    <div className="col-md-9 text-md-right mt-2 mt-md-0">
                        <button  type="button" className="btn btn-primary" onClick={()=>addAdmin()}>
                        <i className="fa fa-plus"></i> Add
                        </button>
                    </div>
                )
            }

        </div>
        <div className="row center-content">
            <div className="col-12">
                <table className="table table-striped table-responsive-md my-3">
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th style={{width: '10%'}} className="text-center">Security</th>
                    <th style={{width: '10%'}} className="text-center">Action</th>
                </tr>
                </thead>
                <tbody >
                    {
                        dataList?.data?.map((item,i)=>{
                            return (
                                <tr key={i}>
                                    <td>{item?.name}</td>
                                    <td>{item?.email}</td>
                                    <td className="text-center">
                                        <div>
                                            <img className="cursor w-image" alt="check" src={checkSecurity(item)}  />
                                        </div>
                                    </td>
                                    <td style={{display:'flex'}}>
                                    {
                                        checkPermission(permissionList.ViewChangePreferences)&&(
                                        <i  className="fa fa-user cursor"
                                            onClick={()=>selectPreferences(item)}></i>
                                        )
                                    }
                                    (
                                        <i  className="fa fa-pencil cursor"
                                            onClick={()=>editAdmin(item)}></i>
                                        )
                                    {
                                        checkPermission(permissionList.UpdateAdmin)&&(
                                        <i  className="fa fa-pencil cursor"
                                            onClick={()=>editAdmin(item)}></i>
                                        )
                                    }
                                    {
                                        checkPermission(permissionList.DeleteAnnouncements)&&(
                                            <DeleteConfirm name="Admin" url={`${apiConfigration.admin.remove}/${item?._id}`} refetch={()=>refetch()} setLoading={(value)=>setLoading(value)}/>
                                        )
                                    }
                                    </td>
                                </tr>
                            )
                        })
                    }
                    
                </tbody>
                </table>
            </div>
            <div className="col-12">
                <Pagination total={dataList?.count} pageIndex={pageIndex} pageSize={getPagination().pageSize} setPageIndex={setPageIndex} setPageSize={setLimit}/>
            </div>
            {
                modelShow&&(
                    <AddEditModel admin={selectedAdmin} show={modelShow} setShow={()=>setModelShow()} setLoading={(value)=>setLoading(value)} refetch={()=>refetch()}/>
                )
            }
            {
                modelShow2&&(
                    <Preferences admin={selectedAdmin} show={modelShow2} setShow={()=>setModelShow2()} refetch={()=>refetch()}/>
                )
            }
        </div>
    </DefaultLayout>
  )
}
