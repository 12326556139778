import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import { useWorldAnnouncementsMutation } from '../../data/announcements';
 import { showToast } from '../../util/helpers';
export default function WorldBoardCastModel({show,setShow}) {
    const { register, handleSubmit, watch,reset, formState: { errors, } } = useForm();
    const { mutate: worldAnnouncementMutate, isLoading: loading } = useWorldAnnouncementsMutation();
    
    function closeModel(){
        setShow(false)
    }

    function onSubmit(inputs)
    {
        worldAnnouncementMutate(
        {
            ...inputs
        },
        {
            onSuccess: (result) => {
                if(result){
                    showToast('World Broadcast Successfully added','success')
                    setTimeout(() => {
                        reset('')
                        setShow(false)
                    }, 800);
                }else{
                    showToast(result.data.message,'error')
                }

            },
            onError: (error) => {
                console.log(error.message);
            }
        })
    }
  return (
    <>
        {
            show&&(
                <div className={`modal-backdrop fade in ${show?'show':''}`}></div>
            )
        }
        <div role="dialog" tabIndex="-1" className={`modal fade ${show?'show':''}`} style={{display: (show?'block':'none')}} aria-modal="true">
            <div role="document" className="modal-dialog">
                <div className="modal-content">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="card m-0 card-sized">
                            <div className="card-header modelHeader">
                                <div className="row">
                                    <div className="col-10 align-self-center">
                                        <h5>Add World Broadcast</h5>
                                    </div>
                                    <div className="col-2 text-right">
                                        <i className="fa fa-times cursor" onClick={()=>closeModel()}></i>
                                    </div>
                                </div>
                            </div>
                    
                            <div className="card-body">
                                <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="col-form-label font-weight-bold required">Broadcast</label>
                                        <textarea className="form-control" rows="2" placeholder="Enter Broadcast" {...register('msg',{required:true})}></textarea>
                                    </div>
                                </div>
                                </div>
                            </div>
                    
                            <div className="card-footer modelFooter">
                                <div className="float-right">
                                    <button className="btn btn-primary mr-2">Save</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>
  )
}
