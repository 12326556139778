import http from "../util/http";
import { useMutation,useQuery } from "react-query";
import { apiConfigration ,getPagination} from "../config/api";

export const tableList = [
    { name: 'Friends', url: 'friends' },
    { name: 'Ignores', url: 'ignores' },
    { name: 'Traces', url: 'traces' }
  ];

async function getUserFriendsDetail({queryKey}){
    const {limit,pageIndex,search,id} = queryKey[1]
    const {pageSize} = getPagination() 

    const { data } =await http.get(`${apiConfigration.user.friends}/${id}?search=${search}&pageIndex=${(pageIndex)}&limit=${limit}&skip=${(pageIndex) * pageSize}`)

    return data;
}

export function useUserFriendsDetailQuery(options){
    return useQuery(
        ['getUserFriendsDetail', options],
        getUserFriendsDetail
      )
}

async function getUserIgnoredFriendsDetail({queryKey}){
    const {limit,pageIndex,search,id} = queryKey[1]
    const {pageSize} = getPagination() 

    const { data } =await http.get(`${apiConfigration.user.ignoredFriends}/${id}?search=${search}&pageIndex=${(pageIndex)}&limit=${limit}&skip=${(pageIndex) * pageSize}`)

    return data;
}

export function useUserIgnoredFriendsDetailQuery(options){
    return useQuery(
        ['getUserIgnoredFriendsDetail', options],
        getUserIgnoredFriendsDetail
      )
}