import React from 'react'
import { useForm } from "react-hook-form";
import { useLoginMutation } from '../../util/api';
import { useNavigate } from "react-router-dom";
import { ToastContainer} from 'react-toastify';
import { showToast,setToken } from '../../util/helpers';

export default function Login() {
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const { mutate: loginMutate, isLoading: loading } = useLoginMutation();
  const navigate = useNavigate();

  function onSubmit({email,password})
  {
    loginMutate(
      {
        email,
        password
      },
      {
        onSuccess: (result) => {
          if(result.data.success){
            localStorage.setItem('user',JSON.stringify(result.data.data))
            setToken(result.data.data.token)
            console.log('navigated')
            navigate('/dashboard')
            showToast('Login successfully!','success')

          }else{
            showToast(result.data.message,'error')
          }
        },
        onError: (error) => {
          console.log(error.message);
        }
      }
    )
    
  }

  return (
    <div className="main-content">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 mx-auto">
            <div className="card-group">
              <div className="card p-md-4">
                <form method='post' action='' onSubmit={handleSubmit(onSubmit)} className="card-body"> 
                  <h1>Sign In</h1>
                  <p className="text-muted">Hello there, Sign in and start managing your Admin.</p>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className='fa fa-user-circle'></i>
                      </span>
                    </div>
                    <input type="text" className="form-control" placeholder="Email" {...register('email',{ required: true })} required/>
                  </div>
                  <div className="input-group mb-4">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className='fa fa-lock'></i>
                      </span>
                    </div>
                    <input type="password" className="form-control" placeholder="Password" {...register('password',{ required: true })} required/>
                  </div>
                  <div className="row">
                    <div className="col-12 text-center">
                      <button type="submit" className="btn btn-primary px-4" >Login</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer/>
    </div>
  )
}
